@import "../../../../../styles/variable";

.deck-modal{
  width: 100% !important;
  height: 100%;
  margin: 0;
  max-width: 100%;
  top:rem(70px);
  padding-bottom: 0;
  .ant-modal-close{
    right: rem(20px);
  }
  .ant-modal-content, .ant-modal-body{
    height: 100%;
    background: #0F0D13;
    .content-list{
      height: calc(100vh - 250px);
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
        background: transparent;
        width: 0;
      }
    }
  }
  .ant-modal-body{
    padding: rem(40px) 0;
    overflow-y: auto;
    .inventory-title{
      color: #E1E2E9;
      font: $fw-bold rem(24px) $font-poppins-regular;
      line-height: rem(36px);
    }
    .inventory-header{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-container{
        border-radius: rem(6px);
        border: rem(2px) solid transparent;
        background:
                  linear-gradient(#FFF2F3,#FFF2F3) padding-box,
                  linear-gradient(to bottom, #BE9C80 0%, #68492A 100%) border-box;
        margin: rem(20px) 0 ;
        .select-type{
          .ant-select-selector{
            height: 100%;
            background: #44240E;
            border-radius: 4px 0px 0px 4px;
            color: white;
            border: 0;
          }
          .ant-select-arrow {
            height: fit-content;
            margin: auto 0;
            top: 0;
            bottom: 0;
          }
          .ant-select-selection-item{
            font-size: 18px;
            height: fit-content;
            margin: auto 0;
          }
        }
        .select-race{
          padding: 0 rem(15px);
          background: #211F1C;
          border-radius: 0 rem(4px) rem(4px) 0 ;
          img{
            width: rem(36px);
            height: rem(36px);
            margin: auto rem(9px);
            cursor: pointer;
            opacity: 0.3;
            &:hover{
              opacity: 1;
            }
          }
          .img-click{
            opacity: 1;
          }
        }
      }
      .total-quantity{
        align-items: center;
        color: #E1E2E9;
        font: $fw-bold rem(24px) $font-poppins-regular;
        line-height: rem(36px);
        .btn-done {
          top: rem(-6px);
          left: rem(17px);
        }
      }
    }

    .inventory-card{
      width: rem(199px);
      background: #1C2023;
      border-radius: 9px;
      margin: rem(20px) rem(10px);
      &:hover{
        box-shadow: 0px 6px 90px rgba(255, 184, 119, 0.3), 0px 6px 30px rgba(253, 204, 132, 0.2);
        .box-id{
          color: #E89F01;
        }
      }
      .box-id{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #FFFFFF;
        font: $fw-bold rem(12px) $font-poppins-regular;
        line-height: rem(20px);
        padding: rem(12px) rem(10px);
        .id-card {
          width: fit-content;
        }
        .ant-checkbox-inner {
          background: #141416;
          border: 1px solid #5F3824;
          box-sizing: border-box;
          border-radius: 2px;
        }
        
        .ant-checkbox-checked .ant-checkbox-inner {
          background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
          border: 1px solid #5F3824;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
      .card-deck__content{
        width: rem(187px);
        margin: 0 auto rem(6px);
        background: #141416;
        border-radius: rem(9px);
        height: rem(217px);
        align-items: center;
        padding: rem(27px) 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #CCCCCC;
        font: $fw-normal rem(10px) $font-poppins-regular;
        img{
          width: rem(126px);
          height: rem(126px);
        }
        .rarity-race{
          position: absolute;
          top: rem(12px);
          right: rem(6px);
          display: flex;
          flex-direction: column;
          img{
            width: rem(24px);
            height: rem(24px);
            margin-bottom: rem(5px);
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .deck-modal .ant-modal-body {
    .inventory-header{
      flex-wrap: wrap;
      .filter-container .select-race{
        img{
          width: rem(25px);
          height: rem(25px);
        }
      }
    }
    .content-list{
      margin-top: rem(20px);
      .ant-row{
        justify-content: center;
      }
    }
  }
}

