@import "../../../styles/variable";

.maintain-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  &__text {
    font: 700 rem(64px) $font-CookieRun-Bold; 
    line-height: rem(87px);
    color: #F0E1C3;
    text-shadow: -2px 0 #5f3824, 0 2px #5f3824, 2px 0 #5f3824, 0 -2px #5f3824;
  }
  &__note {
    text-align: center;
    font: $fw-normal rem(20px) $font-poppins-regular; 
    line-height: rem(32px);
    margin-top: rem(12px);
  }
}
