@import "../../../styles/variable";
.home-container{
  &__view{
    margin: rem(25px) auto rem(100px);
    color: #E89F01;
    font: $fw-normal rem(14px) $font-poppins-regular; 
    line-height: rem(21px);
    text-align: end;
    cursor: pointer;
  }
  .home-table-container{
    justify-content: space-between;
    .table-item{
      font: $fw-normal rem(24px) $font-poppins-regular; 
      line-height: rem(36px);
      color: #E1E2E9;
      margin-top: rem(40px);
      padding-right: rem(20px);
      &.history{
        padding-left: rem(20px);
        padding-right: 0;
      }
      .ant-pagination-simple{
        color: white;
        text-align: end;
        margin-top: rem(25px);
        margin-bottom: rem(25px);
        &-pager input {
          background: #1C1B1E;
          border: 1px solid #292C33;
          border-radius: 4px;
          width: 43px;
          height: 30px;

        }
        .ant-pagination-prev, .ant-pagination-next{
          color: white;
          background: #1C1B1E;
          border: 1px solid #292C33;
          border-radius: 4px;
          height: rem(30px);
          text-align: center;
          span{
            color: white
          }
        }
      }
    }
  }
  .tab-home{
    background-color: #1C1B1E;
    border-radius: 9px;
    margin-top: rem(12px);
    .ant-tabs-nav{
      margin-bottom: 0px;
      background-color: #242424;
      color: white;
      &::before{
        border: none;
      }
      .ant-tabs-tab{
        padding: rem(9px) rem(16px);
        margin-right: 0;
        border-left: 1px solid #3D3E49;
        border-bottom: 0;
        font: $fw-normal rem(14px) $font-poppins-regular; 
        line-height: rem(21px);
        &:hover{
          color:#E89F01
        }
        &:first-child{
          border: 0;
        }
        &-active{
          background-color: #1C1B1E;
          .ant-tabs-tab-btn {
            color:#E89F01
          }
        }
        img {
          margin-right: rem(4px);
          width: rem(20px);
          height: rem(20px);
        }
      }
      .ant-tabs-ink-bar{
        display: none;
      }
    }
    .home-table-item {
      .list-item{
        width: 100%;
        height: rem(1050px);
      }
    }
  }

  .statistic-tab{
    margin-top: rem(25px);
    height: rem(185px);
    .ant-tabs-tab {
      padding: rem(9px) rem(30px) !important;
      border: none !important;
    }
    .statistic-table-item{
      display: flex;
      color: #E1E2E9;
      flex-direction: row;
      font: $fw-normal rem(14px) $font-poppins-regular; 
      line-height: rem(21px);
      padding: rem(40px) rem(30px);
      &__total{
        font: $fw-bold rem(28px) $font-poppins-regular; 
        line-height: rem(42px);
        margin-top: rem(3px);
      }
      img{
        width: rem(65px);
        height: rem(65px);
        margin-right: rem(16px);
      }

    }
  }
 
}


@media (max-width:1294px) {
  .ant-tabs-dropdown-menu-item {
    .icon-tab{
      width: rem(20px);
      height: rem(20px);
    }
  }
  .home-container .tab-home .home-table-item .list-item {
    max-height: rem(1460px);
    height: fit-content;
  }
}

@media (max-width: 575px) {
  .home-container{
    .home-table-container .table-item{
      padding: 0;
      &.history{
        padding: 0;
      }
    }
    .tab-home .home-table-item .list-item {
      height: fit-content;
      max-height: fit-content;
    }
    .statistic-tab{
      height: fit-content;
      .statistic-table{
        &-container{
          padding: rem(15px) 0;
        }
        &-item {
          font: $fw-normal rem(12px) $font-poppins-regular; 
          line-height: rem(10px);
          color: #92939D;
          padding: rem(12px) rem(16px) ;
          img {
            width: rem(36px);
            height: rem(36px);
          }
          &__total{
            font: $fw-bold rem(18px) $font-poppins-regular; 
            color:#E1E2E9;
          }
        }
      }
    }
  }   
}
