.spine-player {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: 0 0;
  position: relative;
}
.spine-player * {
  box-sizing: border-box;
  font-family: "PT Sans", Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
  color: #ddd;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.spine-player canvas {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.spine-player #spine-player-button-logo {
  display: none;
}
.spine-player-buttons {
  display: none;
}
#phaser-example{
position: fixed;
z-index: -9999;
left: -99999999999;
top: -99999999999;
}