@import "../../../../styles/_variable";

.dropdown-noti {
  max-height: rem(818px);
  width: rem(338px);
  overflow: auto;
  padding: rem(24px) 0;
  background: linear-gradient(180deg, #31313D 0%, #0C0C0D 100%);
  border: 1px solid #0F1011;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: rem(4px);

  .ant-dropdown-menu-item:hover{
    background-color: #1C2023;
  }

  &__title{
    color: white;
    font: $fw-bold rem(18px) $font-poppins-regular; 
    padding: 0 rem(16px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .noti-item{
    margin-top: rem(24px);
    width: 100%;
    white-space: pre-wrap;
    padding: 0 rem(16px);
    display: flex;
    img{
      height: fit-content;
      margin-top: rem(10px);
      margin-right: rem(12px);
    }
    &__text{
      font: $fw-normal rem(12px) $font-poppins-regular; 
      line-height: rem(18px);
      color: #6C6D76;
      &-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;  
        margin-bottom: rem(6px);
        font: $fw-normal rem(14px) $font-poppins-regular; 
        line-height: rem(21px);
        color: white;
        div{
          font-weight: 700;
          margin-right: rem(3px);
          &:last-child {
            margin-left: rem(3px);
          }
        }
      }
    }

    &__dot{
      margin: rem(15px) 0 !important;

    }
  }

}

@media (max-width: 575px) {
  .dropdown-noti {
    width: 100%;
  }
}
