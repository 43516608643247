@import "../../../../styles/variable";

.deck-tab {
  width: 100%;

  .desk-note{
    color:#E1E2E9;
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    margin: rem(18px) auto rem(40px) ;
    padding: rem(12px) rem(68px);
    width: fit-content;
    background: linear-gradient(90deg, rgba(114, 92, 51, 0) 0%, rgba(84, 74, 50, 0.6) 50.23%, rgba(114, 92, 51, 0) 100%);
  }
  .note-second{
    margin: 0 auto ;
    padding: 0;
    background: none;
  }
  
  .list-deck{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .item-empty{
      background-image: url("../../../../assets/images/empty-card-deck.svg");
      height: 294px;
      width: 199px;
      background-size: cover;
      &:hover{
        box-shadow: 0px 4px 30px rgba(253, 204, 132, 0.2);
        background-image: url("../../../../assets/images/empty-card-deck-hover.svg");
      }
    }
    .no-hover{
      background-image: url("../../../../assets/images/empty-card-deck.svg");
      height: 294px;
      width: 199px;
      background-size: cover;    
    }
    .item-deck{
      width: rem(199px);
      height: rem(294px);
      background-color: #1C2023;
      border-radius: rem(9px);
      padding: rem(6px);
      .btn-remove {
        width: rem(109px);
        min-width: rem(109px);
        border: none !important;
      }
      
      .id-card {
        width: fit-content;
        color: #CCCCCC;
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(12px);
        padding: rem(6px) 0 rem(12px);
      }
    }

  }
  .btn-save{
    margin-top: rem(40px);
  }
  .ant-btn[disabled] {
    background: linear-gradient(180deg, #82837E 0%, #525150 100%);
    color: #E1E2E9;
    .ant-statistic-content-value {
      color: #E1E2E9;
      font-size: 14px;
    }
  }
  
}

.card-deck__content{
    width: rem(187px);
    margin: 0 auto rem(12px);
    background: #141416;
    border-radius: rem(9px);
    height: rem(201px);
    align-items: center;
    padding: rem(27px) 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #CCCCCC;
    font: $fw-normal rem(10px) $font-poppins-regular;
    position: relative;

    
    .image-card{
      width: rem(126px);
      height: rem(126px);
    }
    .rarity-race{
      position: absolute;
      top: rem(12px);
      right: rem(6px);
      display: flex;
      flex-direction: column;
      img{
        width: rem(24px);
        height: rem(24px);
        margin-bottom: rem(5px);
      }
    }

  }

@media (max-width: 1160px) {
  .deck-tab {
    .list-deck{
      justify-content: center;
      .item-deck, .item-empty{
        margin-right: rem(10px);
        margin-left: rem(10px);
        margin-top: rem(20px);
      }
    }
    .btn-save{
      margin-bottom: rem(30px);
    }
  }

}
.confirm-defense{
  color:#E1E2E9;
  font: $fw-normal rem(14px) $font-poppins-regular;
  line-height: rem(21px);
  top: rem(250px);
  .ant-modal-content {
    border-radius: rem(4px);
  }
  .ant-modal-body{
    padding-top: rem(50px);
    border-radius: rem(4px);
  }

  .btn-container{
    display: flex;
    flex-direction: row;
    margin-top: rem(53px);
    margin-bottom: rem(27px);
    .ant-btn {
      margin: 0 10px;
    }
  }
}


