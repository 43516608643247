@import "../../../styles/_variable";
@import "../../../styles/ultis.scss";

.ant-menu-sub {
    .ant-menu-item {
        a {
            color: #a4a5af!important;

        }
    }
    .ant-menu-item-selected {
        background-color: #0e0e11!important;
        a {
            color: #e89f01!important;
        }
    }
    background-color: #0e0e11!important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected{
    border-bottom: none;
    color: none;
}
.ant-popover-placement-bottomLeft{
    padding-top: 0;
}

.ant-popover, .ant-dropdown {
    position: fixed;
}

.popover-menu>svg:hover path {
    fill: #e89f01;
}

.ant-popover-content {
    
    .ant-popover-arrow{
        display: none;
    }
    .ant-popover-inner{
        background: transparent;
        -webkit-box-shadow: none;
        &-content{
          padding: 0;
          .popover-menu-container{
            padding: 24px 40px;
            background: #201F22;
            box-shadow: 0px 4px 30px rgba(30, 45, 51, 0.25);
            border: 1px solid #0F1011;
            border-radius: 4px;
            width: rem(918px);
            margin-top: rem(-4px);
            .disabled-item {
                .menu-item{
                    &:hover{
                        background: #323134;
                        border-radius: 4px;
                        .item-title{
                            color: #E1E2E9;
                        }
                    }
              
                    .item-title, img{
                        opacity: 0.2;
                    }
                }
            }
          }
          .ant-col{
              &:nth-child(10), &:nth-child(11), &:nth-child(12)  {
                    border-top: 1px solid #3C3A3A;
                    padding-top: rem(20px);
                }
          }

          .menu-item{
              flex-flow: unset;
              padding: rem(10px) rem(20px);
              min-height: rem(74px);
              
              img{
                  width: rem(30px);
                  height: rem(30px);
                  margin-right: rem(12px);
              }
              &:hover{
                  background: #323134;
                  border-radius: 4px;
                  .item-title{
                      color: #E89F01;
                  }
              }
              .ant-col{
                color: #6C6D76;
                font: $fw-normal rem(12px) $font-poppins-regular; 
                line-height: rem(18px);

                
              }
              .item-title{
                color: #E1E2E9;
                font: $fw-bolder rem(14px) $font-poppins-regular; 
                line-height: 100%;
                margin-bottom: rem(5px);
              }
          }

        }
    }
    
    
}

.header {
    position: sticky;
    top: 0px;
    z-index: 100;
    margin: 0px auto;
    padding-left: 60px;
    padding-right: 60px;
    border-bottom: 1px solid #262526;
    overflow: hidden;
    @include themed() {
        color: t("text");
        background-color: t("bg");
    }
    .ant-menu-horizontal > .ant-menu-item {
        border-bottom: none;
        &::after {
            content: none!important;
        }
    }
    .header-menu {
        display: flex;
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #262526;
        @include themed() {
            color: t("text");
            background-color: t("bg");
        }
        .ant-menu-item-selected {
                a {
                    color: #E89F01!important;
                }
            &::after {
                content: none;
            }
        }
        .ant-menu-item {
            display: flex;
            align-items: center;
            font: 500 14px $font-poppins-medium ;
            ::after {
                content: none!important;
            }
            a {
                @include themed() {
                    color: t("text");
                }
            }
            &.connect-btn-block {
                margin-left: auto !important;
                display: flex;
                align-items: center;
            }
            .connect-btn {
                background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
                box-shadow: 0px 4px 4px rgba(72, 77, 9, 0.16);
                border-radius: 6px 6px 9px 9px;
                width: 140px;
                height: 30px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.p2p-item {
                margin-left: 40px;
                &.ml-0{
                    margin-left: 0;
                }
            }
            
            &.user-notification {
                margin-left: auto;
                display: flex;
                align-items: center;
                .ant-menu-title-content {
                    display: flex;
                    align-items: center;
                }
                .notify-block {
                    background: #24242d;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .notify-icon {
                        display: block;
                        width: 21px;
                    }
                }
                // padding-right: 0;
            }
            &.user-logged-in {
                // margin-left: auto;
            }
        }
        .user-logged-in {
            display: flex;
            align-items: center;
            justify-self: center;
        }
        
    }
    
}


@media (max-width: 768px) {
    .header {
        padding: 0;
        .header-menu {
            height: rem(53px);
            padding: rem(12px) 0;
            .img-logo {
                height: rem(26px);

            }
            .ant-menu-item.user-notification{
                margin-left: auto !important;
                margin-right: 0!important;
                .notify-block {
                    width: rem(30px);
                    height: rem(30px);
                    .notify-icon {
                        width: rem(15px);
                    }
                }
            }
        }

        .header-mobile{
            height: rem(45px);
            padding: 0 ;

        }
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item{
            margin-right: 0;
            &-selected{
                border-bottom: none;
            }
            &.mh-auto{
                margin: auto;
            }
        }
        // .ml-10{
        //     margin-left: rem(10px);
        // }


    }

    .ant-popover-content .ant-popover-inner-content .popover-menu-container {
        width: 100%;
    }
    .ant-popover{
        top:43px !important;
    }

}

