@import "../../../styles/variable";

.count-progress {
  min-width: 50%;
  position: relative;
  flex-direction: column;
  z-index: 10;
  height: 21px;
  width: 60%;
  margin: auto;
  margin-top: 5%;
  .remaining {
    display: flex;
    width: 100%;
    color: $color-white;
    font-family: $font-rammetto-one-regular;
    white-space: nowrap;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.05em;
    text-shadow: 0px 1px 0px $color-brown;
    position: absolute;
    left: 3.3%;
    right: 42.92%;
    top: -3px;
    bottom: 14.29%;
    z-index: 200;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $color-brown;
    .box-countdown {
      .ant-statistic-content {
        font-family: $font-rammetto-one-regular;
        font-size: 14px;
        line-height: 18px;
        color: $color-white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $color-brown;
        letter-spacing: -0.05em;
      }
    }
  }
  .progress-bar {
    position: absolute;
    left: 0px;

    .ant-progress-inner {
      border: 1px solid $color-brown-2;
      border-radius: 3px;
      .ant-progress-bg {
        height: 15px;
        box-shadow: 0px 4px 4px rgba(52, 47, 47, 0.25) inset;
      }
    }
  }
}
