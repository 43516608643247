@import "../../../../styles/variable";
.sort-filter {
  width: 244px;
  font-size: 16px;
  border-radius: 9px 9px 0px 0px;
  .container-sort {
    padding: 46px 16px;
    height: auto;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .ant-input::placeholder {
      color: #6c6d76;
    }
    @include themed() {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.17) 0%,
        rgba(57, 48, 35, 0.515625) 48.44%,
        rgba(66, 70, 43, 0) 100%
      );
    }

    .filter-price {
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #e1e2e9;
      margin-top: rem(24px);

      .price-range {
        margin: rem(38px) 0 rem(14px);

        .ant-slider-rail {
          background: #a4a5af;
          opacity: 0.2;
        }
        .ant-slider-handle {
          width: rem(14px);
          height: rem(14px);
          background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
          box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.25);
          border-radius: 3px;
          border: none;
        }
        .ant-slider-track {
          background: #e89f01;
        }
      }
      .input-range {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: #151819;
        &__line {
          width: 6px;
          height: 2px;
          background: #151819;
          border-radius: 2px;
          margin: auto rem(2px);
        }
        .ant-input-number {
          width: rem(100px);
          background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
          border: 1px solid #2e303e;
          box-sizing: border-box;
          border-radius: 6px;
          color: white;
          .ant-input-number-handler-wrap {
            display: none;
          }
        }
      }
    }

    .filter-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #a4a5af;
      align-items: center;
      font: $fw-normal 18px $font-poppins-regular;
      line-height: 21px;
      margin-bottom: rem(20px);
      img {
        margin-right: rem(12px);
      }

      .clear-filter {
        color: #e89f01;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .box-search {
      width: 100% !important;
      margin-top: rem(24px);
      .ant-select {
        width: 100%;
      }

      .atn-search-input-custom .ant-input-group input {
        border-radius: 1.25rem !important;
      }

      .ant-input {
        background: $color-bg;
        font-size: rem(14px);
        line-height: rem(24px);
        padding: 0 rem(12px);
      }

      .ant-input-group {
        display: flex !important;
        flex-direction: row-reverse !important;
        border: none !important;
        > .ant-input {
          &:first-child {
            height: rem(36px);
            background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
            border: 1px solid #2e303e;
            box-sizing: border-box;
            border-radius: 6px;
            z-index: 1;
            color: #e1e2e9;

            &::placeholder {
              font-size: rem(14px);
              line-height: rem(21px);
            }
          }
        }
      }

      button {
        width: rem(16px);
        background: transparent !important;
        border: 0px !important;
        height: 100%;
        border-radius: rem(20px) !important;
        z-index: 2;

        &.ant-input-search-button {
          display: flex;
          align-items: center;
          min-width: unset;
          @include themed() {
            color: t("text") !important;
          }
        }

        span.anticon.anticon-search {
          display: flex;
          height: rem(24px);
          width: rem(24px);

          svg {
            height: 100%;
            width: 100%;
            color: #2e303e;
          }
        }
      }

      .ant-input-group-addon {
        left: rem(170px) !important;
        background-color: transparent !important;
      }

      [ant-click-animating-without-extra-node="true"]::after {
        display: none;
      }
    }

    .ant-select {
      height: rem(36px);
      margin: rem(24px) 0 0;
      color: #e1e2e9;
      width: 100% !important;
      font: $fw-normal 14px $font-poppins-regular;

      &-selector {
        background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
        border: 1px solid #2e303e;
        box-sizing: border-box;
        border-radius: 6px;
      }
      &-arrow {
        color: #e1e2e9;
      }
    }

    &__checkbox {
      span {
        font-size: 16px;
      }
    }
    .filter-chain {
      .ant-checkbox-group {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: rem(8px);

        .ant-checkbox-group-item {
          width: 100%;
          justify-content: center;
          align-self: center;
          display: flex;
        }
      }

      .ant-checkbox + span {
        display: flex;
        padding: rem(5px);
        font: $fw-normal rem(12px) $font-poppins-regular;
        line-height: rem(18px);
        color: #e1e2e9;
        .chain-image {
          width: rem(18px);
          margin-right: rem(4px);
        }
      }
      .ant-checkbox {
        display: none;
        &-group {
          display: flex;
          justify-content: space-between;
        }
        &-wrapper {
          background: linear-gradient(
            180deg,
            #24242d 0%,
            rgba(36, 36, 45, 0) 100%
          );
          border: 1px solid #2e303e;
          border-radius: 6px;
          color: white;
          &-checked {
            // background: rgba(255, 174, 25, 0.1);
            border: 1px solid #f9c744;
          }
        }
      }
    }
    .filter-ele,
    .filter-evolution {
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #e1e2e9;
      margin: rem(24px) 0;
      display: grid;
      .ant-checkbox {
        display: none;
        &-wrapper {
          width: 48%;
          margin: 1%;
          margin-top: rem(12px);
          background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
          border-radius: 4px;
          text-align: center;
          span {
            color: #e1e2e9;
            font: $fw-normal rem(9px) $font-poppins-regular;
            line-height: rem(22px);
            padding: 0;
          }
          &-checked {
            background: rgba(255, 174, 25, 0.1);
            border: 0.5px solid #e89f01;
          }
        }
        &-inner {
          background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
          border: 1px solid #2e303e;
          box-sizing: border-box;
          border-radius: rem(2px);
          width: rem(20px);
          height: rem(20px);
        }
      }
    }
    .filter-evolution {
      margin-bottom: rem(12px);
    }
  }
}

.fixed-filter {
  position: fixed;
  z-index: 99;
  width: 100%;
  background-color: white;
  top: 0px;
  padding-top: 19px;
}
