@import "../../../styles/variable";

.market-tab {
  display: flex;

  .ant-tabs-ink-bar {
    background: linear-gradient(
      90deg,
      rgba(232, 159, 1, 0) 0%,
      #e89f01 47.4%,
      rgba(232, 159, 1, 0) 100%
    );
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }
  &.show-filter {
    .ant-tabs-tab:first-child {
      display: none;
    }
  }
  .ant-tabs-tab {
    margin: 0 30px 0 0;
    &-btn {
      color: #e1e2e9;
      opacity: 0.5;
    }
    &-active .ant-tabs-tab-btn {
      color: #e1e2e9;
      opacity: 1;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: row;
    .btn-card {
      width: 48%;
    }
  }

  .market-cont {
    position: relative;
    margin-top: 0 !important;
    padding-top: 100px;
    .ant-pagination {
      text-align: center;
    }
    .list-item {
      display: flex;
      margin-bottom: rem(20px);
    }
  }
  .total-item {
    position: absolute;
    top: rem(45px);
    left: rem(20px);
    font: $fw-bold rem(24px) $font-poppins-regular;
    line-height: rem(36px);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &-name {
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(18px);
      color: #6c6d76;
      margin-left: rem(5px);
      padding-top: 10px;
    }
  }
  .switch-filter {
    position: absolute;
    top: rem(115px);
    right: 30px;
    display: flex;
    .switch-item {
      margin-right: 15px;
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #a4a5af;

      .ant-switch {
        border: 1px solid #5f3824;
        box-sizing: border-box;
        border-radius: 4px;
        background: linear-gradient(180deg, #3a4442 0%, #3a3a44 100%);
        margin-left: rem(8px);

        &-checked {
          background: #e89f01;
        }
        &-handle {
          top: 1px;
          &::before {
            background: linear-gradient(180deg, #ffffff 0%, #e8eaea 100%);
            box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
          }
        }
      }
    }
  }
  .tab-block {
    width: 100%;
    position: relative;
    .ant-tabs {
      @include themed() {
        color: t("text");
      }
      &-tab-btn {
        img {
          width: rem(20px);
          height: rem(20px);
          margin-right: rem(5px);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .switch-filter {
    top: rem(75px) !important;
    right: rem(-15px) !important;
  }
}
