@import "./ultis.scss";
@import "./variable";

.container {
  @include make-container();
}
.app-element {
  &:before {
    background-attachment: fixed;
    background-size: cover;
    background-image: url("../assets/images/bg-image.png");
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
  }

  @include themed() {
    color: t("text");
  }
}

body {
  font-family: $font-poppins-regular;
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //   background-color: #f5f5f5;
  //   border-radius: 10px;
  // }

  // &::-webkit-scrollbar {
  //   width: 10px;
  //   background-color: #f5f5f5;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   background-color: #e89f01;
  //   background-image: -webkit-linear-gradient(45deg,
  //                                           rgba(255, 255, 255, .2) 25%,
  // 										  transparent 25%,
  // 										  transparent 50%,
  // 										  rgba(255, 255, 255, .2) 50%,
  // 										  rgba(255, 255, 255, .2) 75%,
  // 										  transparent 75%,
  // 										  transparent)
  // }
}

.mr-auto {
}

.master-layout {
  overflow-x: hidden;
  width: 100%;
  margin: 0px;
  max-width: 100%;
}

.master-layout__content {
  max-width: 1440px;
  margin: 0px auto;
  overflow-x: hidden;
  padding-left: 60px;
  padding-right: 60px;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 981px) {
  .master-layout__content {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 768px) {
  .master-layout__content {
    padding-left: 28px;
    padding-right: 28px;
  }
}
@media (max-width: 578px) {
  .master-layout__content {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.master-layout__market {
  width: 100%;
  // margin: 0 60px 0;
  overflow-x: hidden;
  padding: 0 60px;
  min-height: 90vh;
  height: fit-content;
  .tab-collection {
    padding-left: rem(30px);
  }
  .ant-tabs-content-holder {
    .ant-tabs-content .market-cont {
      display: flex;
      flex: auto;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-top: rem(100px);
      &::-webkit-scrollbar {
        display: none;
        background: transparent;
        width: 0;
      }
      .ant-pagination {
        text-align: center;
      }
      .list-item {
        justify-content: left;
        width: rem(1076px);
        margin-left: rem(11px);
        .ant-col {
          padding-left: rem(12px) !important;
          padding-right: rem(12px) !important;
        }
        &__no-filter {
          width: rem(1345px);
        }
      }

      .ant-pagination {
        padding: 0 rem(29px) rem(20px);
      }
    }
  }
}

@media (max-width: 575px) {
  .master-layout__market {
    padding: rem(18px) rem(16px);
    .ant-pagination {
      text-align: center;
    }
    .list-item {
      margin-top: rem(20px);
      justify-content: center;
      margin-bottom: rem(30px);
      height: 100vh - 156;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
        background: transparent;
        width: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 890px) {
  .master-layout__market {
    .market-tab .list-item {
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 902px) and (max-width: 1170px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(538px);
      .ant-col {
        padding-left: rem(12px) !important;
        padding-right: rem(12px) !important;
      }
      &__no-filter {
        width: rem(807px);
      }
    }
  }
}

@media screen and (min-width: 1171px) and (max-width: 1439px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(807px);
      .ant-col {
        padding-left: rem(12px) !important;
        padding-right: rem(12px) !important;
      }
      &__no-filter {
        width: rem(1076px);
      }
    }
  }
}

@media screen and (min-width: 1709px) and (max-width: 1919px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(1345px);
      .ant-col {
        padding-left: rem(12px) !important;
        padding-right: rem(12px) !important;
      }
      &__no-filter {
        width: rem(1614px);
      }
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 2229px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(1550px);
      .ant-col {
        padding-left: rem(9px) !important;
        padding-right: rem(9px) !important;
      }
      &__no-filter {
        width: rem(1824px);
        .ant-col {
          padding-left: rem(6px) !important;
          padding-right: rem(6px) !important;
        }
      }
    }
  }
}

@media screen and (min-width: 2230px) and (max-width: 2539px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(1860px);
    }
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(2170px);
    }
  }
}

@media screen and (min-width: 2540px) and (max-width: 2849px) {
  .master-layout__market
    .ant-tabs-content-holder
    .ant-tabs-content
    .market-cont
    .list-item {
    width: rem(2170px);
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(2480px);
    }
  }
}

@media screen and (min-width: 2850px) and (max-width: 3159px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(2480px);
    }
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(2790px);
    }
  }
}

@media screen and (min-width: 3160px) and (max-width: 3469px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(2790px);
    }
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(3100px);
    }
  }
}

@media screen and (min-width: 3470px) and (max-width: 3779px) {
  .master-layout__market {
    .ant-tabs-content-holder .ant-tabs-content .market-cont .list-item {
      width: rem(3100px);
    }
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(3410px);
    }
  }
}

@media screen and (min-width: 3780px) {
  .master-layout__market
    .ant-tabs-content-holder
    .ant-tabs-content
    .market-cont
    .list-item {
    width: rem(3410px);
    .ant-col {
      padding-left: rem(9px) !important;
      padding-right: rem(9px) !important;
    }
    &__no-filter {
      width: rem(3520px);
      .ant-col {
        padding-left: rem(14px) !important;
        padding-right: rem(14px) !important;
      }
    }
  }
}

.ant-tabs-dropdown-menu-item img {
  width: rem(20px) !important;
  height: rem(20px) !important;
  margin-right: rem(5px);
}

.text-capitalize {
  text-transform: capitalize;
}

.rarity-icon {
  width: rem(24px) !important;
  height: rem(24px) !important;
  margin-right: rem(5px);
}

.rune-item-description {
  background: #2e2e2e;
  border-radius: 10px;
  color: #e1e2e9;
  font: $fw-normal rem(12px) $font-poppins-regular;
  line-height: rem(18px);
  width: 100%;
  justify-content: center;
  align-self: center;
  height: 100px;
  padding: 10px;
}

.rune-image-container {
  height: 100px;
  justify-content: center;
  align-self: center;
  background: black;
  padding: 10px;
  border-radius: 10px;
  display: relative;
}

.rune-image-container .rune-icon {
  height: 100%;
}

.rarity-rune {
  position: absolute;
  width: 20px;
  height: 20px;
  transform: translateY(-6px) translateX(-14px);
}