@import "../../../../styles/variable";

    .product-item{
      padding: rem(30px) rem(21px) rem(12px);
      border-bottom: 1px solid #2A292D;
      height: rem(105px);
      cursor: pointer;
      &:hover{
        background-color: #282C34;
      }
      &:last-child{
        border: none;
      }
      .ant-col{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
      }
      .history-from{
        color: #7B7979;
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(15px);
        padding-left: rem(12px);
        &-col{
          color: #CCCCCC;
          font: $fw-normal rem(14px) $font-poppins-regular;
          line-height: rem(21px);
        }
      }
      &__price{
        display: flex;
        flex-direction: row;
        color: white;
        margin-left: rem(20px);
        img{
          width: rem(25px);
          height: rem(28px);
          margin-right: rem(8px);
        }
        .price{
          font: $fw-bold rem(12px) $font-poppins-regular;
          line-height: rem(18px);
          .purchase{
            font: $fw-normal rem(10px) $font-poppins-regular;
            line-height: rem(15px);
            display: flex;
            align-items: center;
            &__icon{
              line-height: rem(2px);
              height: fit-content;
              margin-right: rem(4px);
            }
          }
          .time{
            font: $fw-normal rem(10px) $font-poppins-regular;
            line-height: rem(15px);
            color: #7B7979;
            margin-top: rem(5px);
          }
        }

      }
      &__container{
        width: rem(62px);
      }
      &__image{
        width: rem(62px);
        height: fit-content;
        max-height: rem(62px);
        margin-top: rem(-20px);
        &.items{
          max-width: rem(68px);
          max-height: rem(68px);
          width: fit-content;
          height: fit-content;
          margin-top: rem(-5px);
        }
      }
      .attribute-container{
        padding-left: rem(25px);
      }
      .attributes-children {
        color: #cccccc;
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(14px);
        text-align: center;
        // .land-size {
        //   font: $fw-normal rem(14px) $font-poppins-regular;
        //   line-height: rem(21px);
        //   display: flex;
        //   flex-direction: row;
        //   justify-content: center;
        //   &__value {
        //     color: #e99f01;
        //     margin-left: rem(4px);
        //   }
        // }
        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: rem(5px);
          div {
            margin: 0 rem(5px) 0;
            text-align: center;
            color: #ffffff;
            font: $fw-normal rem(12px) $font-poppins-regular;
            line-height: rem(18px);
            display: flex;
          }
          img {
            width: rem(16px);
            height: rem(16px);
            margin-right: rem(2px);
          }
        }
        &__level {
          display: flex;
          color: #A89F9F;
          font: $fw-normal rem(12px) $font-poppins-regular;
          line-height: rem(18px);
          margin-bottom: rem(10px);
          padding-left: 4px;
          div {
            color: #e99f01;
            margin: 0 rem(3px);
          }
        }
      }
      
      .rarity-race{
        width: rem(100px);
        height: rem(22px);
        border-radius: 3px;
        color: #FAF0F0;
        font: $fw-normal rem(12px) $font-poppins-regular;
        line-height: rem(18px);
        display: flex;
        padding: rem(4px);
        justify-content: center;
        align-items: center;
        margin-left: rem(16px);
        &.legendary{
          background-color: #FF9D2A;
        }
        &.epic{
          background-color: #C152F0;
        }
        &.rare{
          background-color: #64A7D2;
        }
        &.uncommon{
          background-color: #4DA74F;
        }
        &.common{
          background-color: #BDC3E4;
        }
        &.bundle {
          align-items: flex-start;
          margin-left: rem(-5px);
        }
        &.amount{
          align-items: flex-start;
          margin-left: rem(10px);
          width: rem(200px);
          justify-content: flex-start;

        }
        &__rarity{
          width: rem(14px);
          height: rem(14px);
          margin-right: rem(8px);
        }
      }
      .breed-count{
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(15px);
        color: #CCCCCC;
        margin-left: rem(16px); 
        margin-top: rem(6px);
      }
    }



@media (max-width: 1294px) {
  .product-item{
    padding: rem(30px) rem(5px) rem(12px);
    max-height: rem(160px);
    height: fit-content;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    .breed-count{
      max-width: rem(100px);
    }
    .attribute-container {
      padding: 0 rem(5px);
    }
    .history-from{
      padding-bottom: rem(4px);
      display: flex;
      flex-direction: row;
      align-items: center;      
      &-col{
        padding-left: rem(8px);
        font-size: rem(12px);
      }

    }
  }
    
}
