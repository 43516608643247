@import "../../../../../styles/variable";
.row-infor {
  padding: 5vh;
  padding-bottom: 0;
  
  @media only screen and (max-width: 768px) {
    padding: rem(4px);
    .right-column {
      .name-item {
        text-align: center;
      }
      .box {
        &.evolution {
          text-align: center !important;
        }
      }
    }
  }
  .mb-100 {
    margin-bottom: 100px;
  }
  .detail-item {
    margin-top: rem(24px);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: $color-gray-text-2;
  }
  .detail-about {
    div {
      margin-top: 10px;
    }
    .btn-item {
      padding: 6px;
      margin-right: 10px;
      border-radius: 5px;
      cursor: pointer;
      width: 150px;
      text-align: center;
    }
  }

  .image-block {
    text-align: center;
  }
  &.bundle {
    .bundle-top {
      .name-item {
        text-align: center;
        margin-bottom: rem(24px);
      }
      .image-block {
        display: flex;
        justify-content: center;
      }
    }
    .price-bundle {
      .image-block {
        margin: auto;
      }
    }

    .token-logo {
      width: rem(36px);
    }
    .price-board {
      & > img {
        height: rem(136px);
      }

      .price-container {
        top: 30%;
      }
    }
  }
}

.checkout-bundle-modal {
  .ant-modal-content {
    .ant-modal-body {
      .amount-container {
        margin-bottom: 0;
        margin-top: 24px;
        color: $color-gray;
        width: 67%;
        margin-left: auto;
        margin-right: auto;
        .left-col {
          background: $color-white;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          border-top: 2px solid $color-orange;
          padding-top: 5px;
          font-size: 12px;
          line-height: 12px;
          padding-left: 16px;
          .ant-input{
            border: none;
            &:focus{
              border: none;
            }
          }
          .mb-0 {
            margin-bottom: 0 !important;
            outline: none !important;
          }
        }
        .right-col{
          .ant-select-arrow{
            top: 42%
            
          }   
          .title-select{
            position: absolute;
            top: 5px;
            left: 12px;
            z-index: 2;
            font-size: 12px;
          }    
          .ant-select-selection-item{
            margin-top: rem(15px);
            display: flex;
            align-items: center;
            img{
              width: rem(20px);
              height: rem(20px);
              margin-right: rem(7px);
            }
          } 
          .select-token{
            width: 100%;
            height: 58px;
            border-radius: 0;
            border-top: 2px solid #e89f01;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
            
            .ant-select-selector{
              height: 100%;
              align-items: center;

            }
          }
        }
      }
      .noti-fee{
        font: $fw-normal rem(14px) $font-poppins-regular; 
        line-height: rem(21px);
        color: #E1E2E9;
        margin-top: rem(16px);
        margin-bottom: rem(60px);
        width: 67%;
        margin: rem(10px) auto;
      }
      .ant-carousel {
        .slick-dots-bottom {
          display: none !important;
        }
      }
    }
  }
  .btn-block {
    margin-top: rem(19px);
    padding-bottom: rem(22px);
    margin-bottom: rem(24px);
    &.checkout-bundle-btn {
      height: rem(72px);
      @media only screen and (max-width: 769px) {
        height: rem(72px);
        // position: absolute !important;
        // bottom: 0 !important;
        // left: 0%;
      }
    }
  }
  .bundle-info {
    color: $color-gray-text-2;
    width: 67%;
    margin: auto;
    background-color: $color-black-3;
    padding: rem(14px) rem(16px);
    border-radius: 6px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-top: rem(16px);
      margin-bottom: 15%;
    }
    .price-row {
      margin-bottom: rem(8px);

      .amount-value {
        font: $fw-bold rem(18px) $font-poppins-regular;
        .token-logo {
          display: inline-block;
          width: rem(24px);
          margin-right: rem(8px);
        }
      }
    }
    .amount-row {
      color: $color-gray-text-2;
      margin-bottom: rem(8px);

      img{
        margin-right: rem(8px);
        width: rem(20px);
      }
    }
  }
}
.middle-container {
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-top: 9%;
  }
  .left-arrow,
  .right-arrow {
    position: absolute;
    top: 40%;
    z-index: 1000000;
  }
  .left-arrow {
    left: 16%;
  }
  .right-arrow {
    right: 16%;
  }

  @media only screen and (max-width: 768px) {
    .left-arrow {
      left: 1.5%;
    }
    .right-arrow {
      right: 1.5%;
    }
  }

  .carousel-container {
    color: $color-gray-text-2;

    .bundle-item {
      margin: auto;
      margin-bottom: 3%;
      @media only screen and (max-width: 768px) {
        max-width: rem(244px);
        width: 73%;
      }
      .bundle-modal-item {
        width: 100%;
        min-height: rem(288px);
        margin: auto;
        border-radius: 9px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .nft-card{
          height: auto
        }
        .bottom-info {
          min-height: rem(139px);
          padding-bottom: rem(5px);
          height: 55%;
          border-radius: 7px;
          background: linear-gradient(180deg, #3a4442 0%, #3a3a44 100%);
          position: relative;
          margin-top: rem(113px);
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          text-align: center;
          .bundle-item-image {
            display: block;
            width: 100%;
            max-height: rem(140px);
            position: absolute;
            top: -85%;
            left: 0%;
            object-fit: contain;
            @media only screen and (max-width: 378px) {
              width: 70%;
              margin: 0 15%;
            }
          }
          .attribute-container {
            margin-top: rem(8px);
            margin-bottom: rem(16px);
            .race {
              display: inline-block;
              border-radius: rem(13px);
              padding: 2px 8px;
              margin-left: rem(8px);
              background-color: #666666;
              font-weight: normal;
              font-size: 10px;
              line-height: 16px;
              font-family: $font-poppins-regular;
              color: white;
            }
            .attribute-container__rarity {
              display: inline-block;
              margin-top: rem(8px);
              border-radius: rem(13px);
              padding: rem(2px) rem(8px);
              background-color: #666666;
              font-weight: normal;
              font-size: rem(10px);
              line-height: rem(16px);
              font-family: $font-poppins-regular;
              color: white;
              &.gray {
                background: radial-gradient(
                  82% 82% at 50% 16.67%,
                  #d9d9d9 0%,
                  #cfd4d7 100%
                );
              }
              &.blue {
                background: radial-gradient(
                  82% 82% at 50% 16.67%,
                  #9fc5e8 0%,
                  #6db2f2 100%
                );
              }
              &.yellow {
                background: radial-gradient(
                  82% 82% at 50% 16.67%,
                  #ffd966 0%,
                  #dfab23 100%
                );
              }
              &.red {
                background: radial-gradient(
                  82% 82% at 50% 16.67%,
                  #cc4125 0%,
                  #9d2c15 100%
                );
              }
              &.pink {
                background: radial-gradient(
                  82% 82% at 50% 16.67%,
                  #bd6e97 0%,
                  #aa4077 100%
                );
              }
            }
          }
          .level-row {
            .level-value {
              color: $color-yellow;
            }
          }
        }
      }
    }
  }
}
.checkout-bundle-modal {
  .title-popup {
    margin-top: 7%;
  }
  .bottom-modal-btn {
    padding-bottom: 24px;
  }
  .bundle-card-block {
    .bundle-container {
      .page-row {
        color: $color-white;
        .current-page {
          color: $color-orange;
        }
      }
      .ant-tabs-card {
        .ant-tabs-nav {
          margin-top: 0 !important;
          margin-bottom: rem(29px);
        }
      }
    }
  }
}

.bundle-card-block {
  .bundle-container {
    .ant-tabs-card {
      .ant-tabs-nav {
        &::before {
          border-bottom: none !important;
        }
        .ant-tabs-nav-list {
          justify-content: center;
          flex-wrap: wrap !important;
          max-width: 80%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .checkout-bundle-modal .ant-modal-content .ant-modal-body {
    .amount-container, .noti-fee{
      width: rem(308px);
    }
  }
}