@import "../../../../styles/variable";
 

.season-time__countdown{
    width: rem(240px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    top: rem(32px);
    .time-value {
        font: 400 rem(14px) $font-CookieRun-Bold; 
        line-height: rem(14px);
        color: #F0E1C3;
        text-shadow: 0px 2px 0px #602A1E;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #8F6C4D;
        margin-bottom: rem(4px);
    }

    .time-text {
        font : 300 rem(12px) $font-roboto-thin;
        line-height: rem(14px);
        color: #5D4D32;  
        text-align: center;
        color: #F0E1C3;
        opacity: 0.5;
        margin-right: rem(12px);
    }

}
.time-left{
    font: 400 rem(12px)  $font-poppins-regular; 
    line-height: rem(18px);
    color: #9B8C72;
    margin-left: rem(4px);
    margin-bottom: rem(12px);
    .time-value{
        font: 400 rem(14px)  $font-poppins-regular; 
        line-height: rem(19px);
        color: #E7CEA0;
    }
}

@media (max-width: 575px) {
    .season-time__countdown{
        width: rem(269px);
        height: rem(50px);
        top: rem(27px);
        .time-value {
            font: 500 rem(25px) $font-poppins-regular; 
            margin-bottom: 0;
        }
        .time-text{
            font : 300 rem(12px) $font-roboto-thin;
            line-height: rem(16px);

        }
    }
}
