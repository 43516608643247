@import "../../../../styles/variable";
.dna-item {
  display: block;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-overflow: clip;
  overflow: scroll;
  max-width: 90%;
  font-size: rem(12px);
  font-weight: $fw-normal;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .rarity-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  &.red {
    background: radial-gradient(
      82% 82% at 50% 16.67%,
      #cc4125 0%,
      #9d2c15 100%
    );
    order: 6;
  }
  &.green {
    background: $color-green;
    order: 7;
  }
  &.blue {
    background: radial-gradient(
      82% 82% at 50% 16.67%,
      #9fc5e8 0%,
      #6db2f2 100%
    );
    order: 8;
  }
  &.pink {
    background: radial-gradient(
      82% 82% at 50% 16.67%,
      #bd6e97 0%,
      #aa4077 100%
    );
    order: 5;
  }
  &.yellow {
    background: radial-gradient(
      82% 82% at 50% 16.67%,
      #ffd966 0%,
      #dfab23 100%
    );
  }
  &.silver {
    background: radial-gradient(
      82% 82% at 50% 16.67%,
      #d9d9d9 0%,
      #cfd4d7 100%
    );
  }
}
