@import "../../../styles/variable";

.match-result-page {
  margin-bottom: 20px;
  .title-page {
    line-height: 100%;
    margin: 10px 0px 10px;
    font: $fw-bold rem(32px) $font-poppins-medium;
    color: #e1e2e9;
  }
  .col-mon-items {
    display: flex;
    justify-content: space-between;
  }
  .round-numb-txt {
    font: $fw-normal rem(14px) $font-poppins-regular;
    text-align: center;
    color: #F0E1C3;
    @media only screen and (max-width: 576px) {
      font-size: 12px;
    }
  }
  .address-block {
    font: $fw-normal rem(16px) $font-poppins-regular;
    line-height: 21px;
    text-align: center;
    margin-bottom: 24px;
    &.yl {
      color: #EBC276;
    }
    &.red {
      color: #E83B3B;
    }
  }
  .result-txt {
    font: $fw-bold rem(24px) $font-CookieRun-Bold;
    text-transform: uppercase;
    .vic {
      color: #f0e1c3;
      text-shadow: -2px 0 #5f3824, 0 2px #5f3824, 2px 0 #5f3824, 0 -2px #5f3824;
    }
    .fail {
      color: #a43535;
      text-shadow: -2px 0 #431d15, 0 2px #431d15, 2px 0 #431d15, 0 -2px #431d15;
    }
    line-height: 150%;
    text-align: center;
    @media only screen and (max-width: 576px) {
      font-size: 16px;
  }
  }
  .txt-bonus {
    &.right {
      background-image: url("../../../assets/images/bonus-bg-win.png");
    }
    &.left {
      background-image: url("../../../assets/images/bonus-bg.png");
    }
    padding: 4px;
    width: 310px;
    height: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    font: $fw-normal rem(12px) $font-poppins-regular;
    line-height: 18px;
    margin-top: 5px;
    text-align: center;
    &.yl {
      color: #E89F01;
    }
    &.red {
      color: #E83B3B;
    }
  }
  .row-result {
    margin-bottom: 30px;
  }
  .img-result {
    height: 461px;
  }
  .text-elo {
    color: #f0e1c3;
    text-shadow: -1px 0 #5f3824, 0 1px #5f3824, 1px 0 #5f3824,
      0 -1px #5f3824;
    color: #F0E1C3;
    line-height: 150%;
    font: $fw-bold rem(32px) $font-CookieRun-Bold;
    text-align: center;
    @media only screen and (max-width: 576px) {
      font-size: 28px;
  }
  }
  .rs-block {
    position: relative;
    &.lose {
      .point-cont {
        top: 199px;
        left: 64%;
      }
    }
    .point-cont {
      position: absolute;
      top: 215px;
      left: 57%;
      transform: translate(-50%, -50%);
      text-align: center;
      text-transform: uppercase;
      .numb-change {
        font: $fw-bold rem(42px) $font-CookieRun-Bold;
        line-height: 150%;
        text-shadow: -2px 0 #5f3824, 0 2px #5f3824, 2px 0 #5f3824,
          0 -2px #5f3824;
        color: #f9c744;
        margin-bottom: 6px;
      }
    }
  }
}
