@import "../../../../../styles/variable";

.make-offer-modal {
  width: 600px;
  .ant-modal-content {
    width: 600px;
  }
  @media only screen and (max-width: 768px) {
    .ant-modal-content {
      .ant-modal-body {
        background: $color-black;
        .offer-modal-box {
          background: $color-black-3;
        }
      }
    }
  }
}

.row-infor .modal-image {
  width: 100%;
  margin-right: 10%;
}
.pd-box{
  padding: rem(14px) rem(16px);
}

.offer-modal-box {
  flex-direction: column !important;
  .info-item {
    // display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    .value {
      &.d-block {
        display: block;
        text-align: right;
        word-break: break-word;
        max-width: 80%;
        word-wrap: break-word;
      }
      .rune {
        height: 60%;
        max-height: 24px;
        margin-right: 8px;
      }
    }
  }
}

.bottom-modal-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  &.mt-68 {
    margin-top: 68px !important;
  }
}

.estimated-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.btn-accept {
  padding: 6px;
  margin-right: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.received-mon {
  color: rgb(228, 208, 29) !important;
}

.modal-item-container {
  margin-bottom: 8px;
}
.receive-note {
  margin-top: 8px;
}

.estimated-amount {
  margin-top: rem(18px);
  .amount-value {
    margin-left: rem(14px);
    color: $color-orange;
  }
}

.mt-20 {
  margin-top: rem(20px);
}

@media only screen and (max-width: 769px) {
  .make-offer-modal {
    margin: 0 !important;
    &.general {
      width: 100vw !important;
      max-width: 100vw !important;
      position: fixed;
      top: 0;
      .ant-modal {
        &-content {
          width: 100% !important;
          height: 100vh !important;
          background: #000000;
          .ant-modal-close {
            .close-icon {
              display: none;
            }
          }
        }
        &-body {
          height: 95vh;
          overflow: scroll;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          ::-webkit-scrollbar {
            display: none;
          }
          .offer-info {
            height: 60vh;
            justify-content: center;
            .left-column {
              justify-content: center;
              max-height: rem(180px);
              .item-image {
                display: flex;
                // height: rem(180px);
              }
            }
            .rarity-box {
              justify-content: center;
            }
          }
          .offer-name,
          .id-modal {
            text-align: center;
          }
          .btn-block {
            height: 48px;
            margin-bottom: 15%;
            align-self: end;
          }
          .mt-68 {
            margin-top: rem(168px);
          }
        }
      }
    }
  }
}

.rarity-box {
  display: flex;
  .attribute-container__rarity {
    display: flex;
    margin-top: rem(8px);
    border-radius: rem(13px);
    padding: rem(2px) rem(8px);
    background-color: #666666;
    font-weight: normal;
    font-size: rem(10px);
    line-height: rem(16px);
    font-family: $font-poppins-regular;
    color: white;
    &.gray {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #d9d9d9 0%,
        #cfd4d7 100%
      );
    }
    &.blue {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #9fc5e8 0%,
        #6db2f2 100%
      );
    }
    &.yellow {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #ffd966 0%,
        #dfab23 100%
      );
    }
    &.red {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #cc4125 0%,
        #9d2c15 100%
      );
    }
    &.pink {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #bd6e97 0%,
        #aa4077 100%
      );
    }
  }
}
