@import "../../../../../styles/variable";

.plot-item {
  background: #141416;
  border-radius: 9px;
  margin-bottom: 13px;
  padding: 6px;
  &:last-child {
    margin-bottom: 0px;
  }
  width: 100%;
  .plot-image {
    width: 123px;
    height: 123px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding-right: 10%;
    }
  }
  .plot-info {
    .name {
      margin-top: 16px;
      font-weight: 600;
      font-size: 18px;
    }
    .info-type {
      margin-top: 15px;
    }
    @media only screen and (max-width: 768px) {
      .rarity-race{
        justify-content: flex-end;
      }
      .rarity-race__rarity {
        width: rem(18px) !important;
      }
      .value-name {
        font-size: rem(12px);
        color: $color-gray-6;
      }
    }
  }
  .level {
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    margin-top: rem(16px);
    text-align: right;
    padding-right: 10px;

    .content {
      font-weight: 600;
      font-size: rem(14px);
      margin-bottom: rem(17px);
      .level-numb {
        color: #e99f01;
        margin: 0 rem(3px);
      }
    }
    .rarity-race {
      display: flex;
      align-items: center;
      justify-content: end;
      .value-name {
        text-transform: none;
      }
    }
    .rarity-race__rarity {
      width: 24px;
      height: 24px;
    }
    @media only screen and (max-width: 768px) {
      padding-right: 0;
      .rarity-race__rarity {
        margin-right: rem(8px) !important;
      }
      .content {
        font-weight: $fw-normal;
        font-size: 12px;
        line-height: rem(12px);
        color: $color-gray-6;
        .level-title {
          display: none;
        }
        .level-numb {
          color: #e99f01;
          margin: 0 rem(3px);
        }
      }
    }
  }
}
