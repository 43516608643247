@import "../../../styles/variable";

.create-bundle-page {

  &__title{
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 24px;

    button{
      margin-left: 30px;
      width: 100px;
      height: 26px;
      display: flex;
      align-items: center; 
      justify-content: center;  
      border-radius: 4px;
      background-color: #E5E5E5; 
    }
    
  }

  .ant-tabs-content {
    .market-cont {
      height: 900px;
    }
  }

  .create-bundle-tab{
    flex:1;
    padding-left: 20px;
    position: relative;
    .btn-next{
        position: absolute;
        top: rem(109px);
        right: 0;
        border: none;
    }
    .quantity{
      position: absolute;
      right: rem(172px);
      top: rem(119px);
      color: #E1E2E9;
      font: $fw-bold rem(20px) $font-poppins-regular; 
      line-height: rem(18px);

    }
    .note{
      color: #E1E2E9;
      font: $fw-normal rem(14px) $font-poppins-regular; 
      line-height: rem(21px);
      position: absolute;
      top: rem(116px);
      left: 280px;
    }

    
  
  }
  .bundle-name{
    width: 500px;
    height: 40px;
  }

  .ant-tabs-ink-bar {
        background: linear-gradient(90deg, rgba(232, 159, 1, 0) 0%, #E89F01 47.4%, rgba(232, 159, 1, 0) 100%);
  }

  .ant-tabs-top > .ant-tabs-nav::before {
      border: none;
  }
  .ant-tabs-tab{
      margin-right: rem(30px);
      &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4){
        margin: 0;
      }
      &-btn {
          color: #E1E2E9;
          opacity: 0.5;
          margin: 0;
          img{
            width: rem(20px);
            height: rem(20px);
          }

      }
      &-active .ant-tabs-tab-btn {
          color: #E1E2E9;
          opacity: 1;
      } 
         
  }
  .m-0 {
    margin: 0 !important;
  }
}

@media (max-width: 575px) {
  .create-bundle-page{
    .create-bundle-tab {
      padding-left: 0;
      .list-item{
        margin-top: 0;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
      a{
        width: 100%;
      }
      .note{
        position: unset;
        left: 0;
        width: 100%;
        margin-top: rem(18px);
      }
    .btn-next{
      position: unset;
    }
  }
  }
  
}

