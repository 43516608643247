@import "../../styles/variable";

.connect-wallet-page {
    padding-top: 60px;
}

.token-block {
    display: inline-block;
}
.swap-block {
}
.signup-info {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: $font-poppins-regular;
  margin-top: 8px;
  color: #666666;
}
.wallet-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 20px 30px rgba(97, 97, 97, 0.1);
  border-radius: 12px;
  cursor: pointer;
  .wallet-txt {
  }
}
.connect-wallet-box {
    border-top: 1px solid #EAEAEA;
    padding-top: 20px;
    margin-top: 20px;
}
.connect-txt {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  font-family: $font-poppins-medium;
  margin-bottom: 8px;
}
