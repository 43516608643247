$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin media-query($key, $breakpoint: $grid-breakpoints) {
  @media (min-width: map-get($breakpoint, $key)) {
    @content;
  }
}

@mixin make-container(
  $max-width: $container-max-widths,
  $breakpoint: $grid-breakpoints
) {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 to-rem(15px);
  @each $breakpoint, $container-max-width in $max-width {
    @include media-query($breakpoint) {
      max-width: $container-max-width;
    }
  }
}

$base-size: 16px;

@function to-rem($size, $context: $base-size) {
    @return $size / $base-size * 1rem;
}

.text-center {
  text-align: center;
}

@for $p from 5 through 15 {
  .pl-#{$p} {
    padding-left: #{$p + 'px'};
  }

  .pr-#{$p} {
    padding-right: #{$p + 'px'};
  }

  .mt-#{$p} {
    margin-top: #{$p + 'px'};
  }
}

.image-icon {
  width: to-rem(24px);
  height: to-rem(24px);
}

.cursor-pointer {
  cursor: pointer;
}
.upper-text{
  text-transform: uppercase;
}

@keyframes backgroundColorPalette {
  @for $i from 0 through 50 {
      #{$i*2+'%'} {
        background: linear-gradient(92.34deg, #3284ff (-5.26 + $i) * 1%, #fe2b5e (111.85 - $i) * 1%);
      }
  }
}

@keyframes textColorPalette {
  @for $i from 0 through 50 {
      #{$i*2+'%'} {
        background: linear-gradient(92.34deg, #3284ff (-5.26 + $i) * 1%, #fe2b5e (111.85 - $i) * 1%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  }
}

@keyframes loading {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

