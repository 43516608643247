@import "../../../styles/variable";

.card-modal {
    .nft-card {
        margin-right: 15px;
    }
    .title-modal {
        color: #ffffff;
        text-align: center;
        text-shadow: 0px 1.5px 0px #f2b109,
            0px 6px 90px rgba(255, 184, 119, 0.3),
            0px 6px 30px rgba(173, 185, 65, 0.2);
        font: $fw-bold rem(32px) $font-CookieRun-Bold;
    }
    .items-txt {
        color: #f2f2f2;
        font: $fw-normal rem(12px) $font-poppins-regular;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 40px;
    }
}
