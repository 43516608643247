@import "../../../../styles/variable";

.mon-item {
    position: relative;
    height: 100px;
    width: 87px;
    
    &.center {
        display: flex;
        align-items: center;
    }
    .img-block {
        width: 87px;
        height: 87px;
        @media only screen and (max-width: 576px) {
            width: 48px;
            height: 48px;
        }
        border-color: #141416;
        /* Legendary */

        border: 3px solid;
        box-sizing: border-box;
        border-radius: 50px;
        overflow: hidden;
        position: relative;
        &.small {
            width: 60px;
            height: 60px;
        }
        &.red {
            border-color: #cc4125;
        }
        &.green {
            border-color: $color-green;
        }
        &.blue {
            border-color: #9fc5e8;
        }
        &.pink {
            border-color: #bd6e97;
        }
        &.yellow {
            border-color: #ffd966;
        }
        &.silver {
            border-color: #d9d9d9;
        }
    }
    .rarity-race {
        display: flex;
        text-transform: uppercase;
        align-items: center;
        &__rarity {
            position: absolute;
            right: 0px;
            top: -5px;
            width: rem(30px);
            height: rem(30px);
            @media only screen and (max-width: 576px) {
                width: rem(17px);
                height: rem(17px);
            }
            object-fit: contain;
            @media only screen and (max-width: 768px) {
                width: rem(24px);
                height: rem(24px);
            }
        }
    }

    .mon-img {
        width: 117.31px;
        height: 117.31px;
        position: absolute;
        left: -19px;
        top: -17px;
        @media only screen and (max-width: 576px) {
          width: 64px;
          height: 64px;
          left: -8px;
          top: -8px;
      }
    }
    .point-numb {
        @media only screen and (max-width: 576px) {
            width: 14px;
            height: 14px;
            font: $fw-normal rem(12px) $font-poppins-regular;
            padding-top: 0px;
        }
        width: 24px;
        height: 24px;
        background: linear-gradient(180deg, #d55050 0%, #a43535 100%);
        padding-top: 2px;
        font: $fw-normal rem(14px) $font-poppins-regular;
        text-align: center;
        color: #ffffff;
        z-index: 100;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50px;
    }
}
