@import "../../../styles/variable";

.ant-select-dropdown {
    padding: 0px;
}
.ant-select-item {
    @include themed() {
        color: t("text") !important;
        background-color: t("bg-input") !important;
    }
}
.chain-option {
    .chain-logo {
        img {
            margin-right: rem(8px);
            width: rem(24px);
            display: inline-block;
            text-align: center;
        }
       
    }
}
