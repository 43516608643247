@import "../../../styles/variable";

.confirm-bundle-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .list-bundle-item{
    flex:1;
    margin-right: rem(100px);
    margin-top: rem(80px);
    // width: rem(807px);
    position: relative;
    .nft-card {
      max-width: 15.25rem;
      min-height: rem(326px);
      .card-mon{
        margin-bottom: 0;
      }
    }
    
    .ant-col{
      margin-right: rem(12px);
    }

    &__title{
      color: #E1E2E9;
      font: $fw-bold rem(32px) $font-poppins-regular; 
      line-height: rem(32px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: rem(40px) 0 rem(16px) rem(25px);
      &-length{
        font: $fw-normal rem(18px) $font-poppins-regular; 
        line-height: rem(18px);
      }
    }
  
  }
  .bundle-info{
    min-height: 100vh;
    width: rem(445px);
    padding: rem(52px) rem(30px);
    font: $fw-bold rem(24px) $font-poppins-regular; 
    line-height: rem(24px);
    color: #E1E2E9;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.17) 0%, rgba(70, 65, 43, 0.515625) 48.44%, rgba(114, 99, 51, 0) 100%);
    &__quantity{
      color: #E1E2E9;
      opacity: 0.5;
      font-size: rem(18px);
      margin-top: rem(6px);
    }
    .btn-container{
      display: flex;
      flex-direction: row;
    }
    .left-col {
        background: $color-white;
        padding-left: 16px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 2px solid $color-green;
        padding-top: 5px;
        font-size: 12px;
        line-height: 12px;
        margin-top: rem(24px);
        .mb-0 {
          margin-bottom: 0 !important;
          outline: none !important;
        }
    }

    .right-column{
      padding-left: 0 !important;
      padding-right: 0 !important;

    }
    .title-select{
      position: absolute;
      top: 27px;
      left: 12px;
      z-index: 2;
      font-size: 12px;
    }

    .select-token{
      width: 100%;
      height: rem(51px);
      border-radius: 0;
      border-top: 2px solid $color-green;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-top: rem(24px);
      .ant-select-selection-item{
        display: flex;
        align-items: center;
        img{
          width: rem(20px);
          height: rem(20px);
          margin-right: rem(7px);
        }
      }

      .ant-select-selector{
        height: 100%;
        align-items: center;

      }
    }

    .noti-fee{
      font: $fw-normal rem(14px) $font-poppins-regular; 
      line-height: rem(21px);
      color: #E1E2E9;
      margin-top: rem(16px);
      margin-bottom: rem(30px);
    }
    .amount-item {
      border: none !important;
      padding: 0;
      margin: 0;
      input {
        outline: none !important;
      }

      .prefix-input {
        height: 16px;
      }
    }
    .btn-approve, .btn-cancel{
      width: 100%;
      height: rem(37px);
    }
    .btn-cancel{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #E1E2E9;
      margin-top: rem(9px);
      cursor: pointer;
    }
  }
  
  
}

@media (max-width: 575px) {
  .confirm-bundle-page {
    flex-direction: column-reverse;
    .bundle-info{
      width: 100%;
      padding: rem(24px) rem(16px);
      height: fit-content;
      min-height: min-content;
      border-bottom: 1px solid #474747;;
    }
    .list-bundle-item{
      width: 100%;
      .ant-col {
        margin-right: 0 ;
      }
      .ant-row{
        justify-content: center;
      }
      &__title{
        padding: rem(24px)0 0;
        font: $fw-normal rem(14px) $font-poppins-regular; 
        line-height: rem(21px);
      }
    }
  }
}
