@import "../../../../styles/variable";
.confirm-modal {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .confirm-modal-btn {
    padding-bottom: rem(24px);
  }
  @media only screen and (max-width: 768px) {
    .ant-modal-content {
      .ant-modal-body {
        background: $color-black;
        .offer-modal-box {
          background: $color-black-3;
        }
      }
    }
  }
  .ant-modal-body {
    width: 600px !important;
  }

  .ant-modal-body {
    width: 300px;
    margin: 20px auto;
    .nft-card_name {
      font: bold;
      text-align: center;
    }

    .confirm-btn {
      margin: 0 auto;
      display: flex;
      margin-top: 20px;
    }

    .price {
      &-input {
        display: flex;
        margin-left: 35px;
        align-items: center;
        input {
          margin-right: 5px;
        }
      }
    }
    .right-column{
      padding-left: 0 !important;
      padding-right: 0 !important;

    }
    .title-select{
      position: absolute;
      top: 5px;
      left: 12px;
      z-index: 2;
      font-size: 12px;
    }
    .select-token{
      width: 100%;
      height: 100%;
      border-radius: 0;
      border-top: 2px solid #e89f01;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: white;
      border-left: 1px solid #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
          width: rem(24px);
          height: rem(24px);
          margin-right: rem(8px);
        }

      .ant-select-selection-item{
        display: flex;
        align-items: center;
        img{
          width: rem(24px);
          height: rem(24px);
          margin-right: rem(8px);
        }
      }
      .ant-select-selector{
        height: 100%;
        align-items: center;

      }
      .ant-select-arrow{
        top:40%
      }
    }
  }
}

.ant-select-dropdown{
  .ant-select-item-option-content{
    .token-icon{
      width: rem(20px);
      height: rem(20px);
      margin-right: rem(7px);
    }
  }
}

.price-row {
  .left-col {
    padding-right: 16px;
  }
}

.amount-row {
  &.put-price {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 769px) {
  .make-offer-modal {
    margin: 0 !important;
    &.confirm-modal {
      width: 100vw !important;
      max-width: 100vw !important;
      position: fixed;
      top: 0;
      .ant-modal {
        &-content {
          width: 100% !important;
          height: 100vh !important;
          .ant-modal-close {
            .close-icon {
              display: none;
            }
          }
        }
        &-body {
          height: 100vh;
          width: 100vw !important;
          margin: 0;
          .offer-info {
            height: 60vh;
            justify-content: center;
            .left-column {
              justify-content: center;
              max-height: rem(180px);
              .item-image {
                display: flex;
                // height: rem(180px);
              }
            }
          }
          .offer-name,
          .id-modal {
            text-align: center;
          }
          .btn-block {
            height: 48px;
            margin-bottom: 20%;
            align-self: end;
          }
          .mt-68 {
            margin-top: rem(168px);
          }
        }
      }
    }
  }
}
