@import '../../../styles/variable';

.img-loading {
	position: absolute;
	width: rem(64px);
	height: rem(64px);
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin:  auto;
	animation: loading 2s linear infinite forwards;


	&[data-size='small-icon'] {
		width: rem(30px)!important;
		height: rem(30px);
		position: unset;
	}

	&[data-size='medium'] {
		position: absolute;
		top: 46%;
		left: 47%;
	}

	&[data-size='large'] {
		position: absolute;
		top: 40%;
		left: 43%;
		width: rem(80px)!important;
		height: rem(80px)!important;
	}
}

.img-preview-loading {
	width: rem(40px)!important;
	height: rem(40px);
	min-height: auto!important;
}
