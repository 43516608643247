@import "../../../../styles/variable";

.row-infor {
  .expired-row {
    .ant-table-cell{ 
      padding-top: 30px ;
    }
    .address-col {
    &::before {
      content: '';
      background-image:url("../../../../assets/images/ex-icon.svg");
      background-size:cover;
        position:absolute;
        width: 70px;
        height: 29px;
      margin-left: -28px;
      top: 10px;
    }}
  }
  &.detail-mon {
    color: $color-gray-text-2;
    width: 1200px;
    margin: auto !important;
    padding: rem(80px) 0;
    font-family: $font-poppins-medium;
    row-gap: 0px;
    justify-content: space-between;
    @media only screen and (max-width: 1165px) {
      width: auto;
      padding: 0;
    }
    .left-container{
      width: rem(513px);
      @media only screen and (max-width: 1165px) {
        margin: auto;
      }
    }
    .right-column{
      .icon-loading {
        width: rem(64px);
        height: rem(64px);
        margin-top: 70px;
        animation: loading 2s linear infinite forwards;
      }
      width: rem(680px);
       @media only screen and (max-width: 1165px) {
         width: 100%;
        .name-item, .id-item {
          text-align: center;
        }
        .nft-infor{
          justify-content: center;
        }
      }
    }
    .history-paging{
      display: flex;
      justify-content: end;
      margin-top: rem(20px);
      .anticon svg {
        color: white
      }
      .ant-pagination-item a {
        color: white
      }
      .ant-pagination-item-active a {
        color: black;
      }
    }
    .col-fixed {
      position: fixed;
      padding-bottom: 100px;
      width: rem(513px);

      &.top-0 {
        top: 0;
      }
      &.top-50 {
        top: -20%;
      }
      @media only screen and (max-width: 1165px) {
        position: static;
        max-width: 100%;
        margin: auto;

      }
    }
    .about-list {
      .box-list {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20px);
        @media only screen and (max-width: 768px) {
          gap: rem(6px);
        }
      }
      .box {
        width: 180px;
        color: $color-white;
        border-radius: 4px;
        padding: 0 16px;
        @media only screen and(max-width: 768px) {
          // width: rem(106px);
          width: auto;
          min-width: 50%;
        }
        &.red {
          background-color: $color-red-2;
        }
        &.green {
          background-color: $color-green;
        }
        &.pink {
          background-color: $color-pink;
        }
        &.yellow {
          background-color: $color-yellow;
        }
        &.evolution {
          width: 100%;
          text-align: start;
          margin-bottom: 4px;
          order: 1;
          & > span {
            margin-right: 8px;
          }
        }
        &.rarity {
          order: 2;
        }
        &.race {
          order: 3;
        }
        &.level {
          order: 4;
        }

        &.type {
          order: 5;
        }

        &.other {
          order: 100;
        }
        .box-name {
          text-transform: uppercase;
          color: $color-gray-text-3;
          font-size: 14px;
          line-height: 28px;
          font-weight: $fw-normal;
          margin-bottom: 12px;
          @media only screen and (max-width: 768px) {
            color: $color-gray-6;
            text-transform: none;
            font-size: 12px;
            margin-bottom: 3px;
            line-height: rem(18px);
          }
        }

        .box-value {
          position: relative;
          font-weight: $fw-bold;
          height: 30px;
          text-transform: uppercase;
          @media only screen and (max-width: 768px) {
            font-weight: $fw-normal;
          }
          .content {
            display: flex;
            align-items: center;
            height: 100%;
          }
          .box-line {
            position: absolute;
            height: 3px;
            width: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(20, 20, 31, 0.4) 16%,
              rgba(23, 22, 22, 0.1) 100%
            );
            filter: blur(5px);
          }
        }
      }
    }

    .plot-box {
      background: $color-gray-4;
      border-radius: 12px;
      padding: 12px 12px;
      color: white;
      .name {
        font-weight: 600;
        font-size: 18px;
      }
      .info-type {
        font-size: 14px;
        font-family: $font-poppins-regular;
        font-weight: normal;
        margin-top: 15px;
      }
      @media only screen and (max-width: 768px) {
        .name {
          font-size: rem(14px);
          line-height: rem(14px);
        }
        .info-type {
          font-size: rem(12px);
          color: $color-gray-6;
        }
      }
    }

    .item-box {
      background: $color-gray-4;
      border-radius: 12px;
      padding: 14px 0;
      display: flex;
      justify-content: start;
      @media only screen and (max-width: 768px) {
        justify-content: space-between;
      }
      &.disposition-list {
        display: block;
        ul {
          margin-left: 20px;
        }
      }
      .mark-name{
        padding: rem(14px) rem(22px) 0;
      }
      .mark-container{
        display: flex;
        justify-content: center;
        padding: rem(14px) rem(22px);
        &__description{
          margin-left: rem(20px);
          background: #2E2E2E;
          border-radius: 4px;
          padding: rem(12px);
        }
      }
    }
    .description-tab {
      .rarity-race {
        display: flex;
        text-transform: uppercase;
        align-items: center;
        &__rarity {
          width: rem(30px);
          height: rem(30px);
          margin-right: rem(12px);
          object-fit: contain;
          @media only screen and (max-width: 768px) {
            width: rem(24px);
            height: rem(24px);
          }
        }
      }

      .description-content{
        background: #1C2023;
        border-radius: 12px;
        width: 100%;
        padding: rem(27px) rem(50px);
        color: #ffffff;
        font: $fw-normal rem(14px) $font-poppins-regular;
        line-height: rem(21px);
        &-container{
          display: flex;
          align-items: center;
          margin-bottom: 7px;
          &__dot{
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: white;
            margin-right: rem(3px);
          }
          
        }
      }
    }
    .detail-item {
      margin-top: rem(24px);
      margin-bottom: 14px;
      font-size: 18px;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      &.disposition-item {
        margin-bottom: 5px;
      }
    }
    .name-item {
      font-size: 32px;
      font-weight: $fw-bold;
      margin-bottom: 27px;
      color: $color-gray-text-2;
      &.offer-name {
        font-size: 24px;
        margin-bottom: 8px;
      }
      .id-item {
        font-size: 14px;
        font-weight: $fw-normal;
        font-family: $font-poppins-light;
      }
    }
    .item-image {
      max-height: rem(513px);
      object-fit: contain;
      &.decor-image{
        width: rem(380px);
      }
      @media only screen and (max-width: 768px) {
        &.p2p-detail-img {
          width: 100%;
          margin-bottom: 12%;
        }
        &.decor-image{
          width: 80%;
          padding: 40px 0;
        }
      }
    }
    .image-block {
      text-align: center;
    }

    .number-offer {
      font-size: 14px;
      font-weight: normal;
    }
   
    .btn-block {
      margin-top: 40px;
      position: relative;
      z-index: 0;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      padding-bottom: 30px;
      
      &.detail-btn-container {
        @media only screen and (max-width: 768px) {
          justify-content: center;
        }
        .purchase{
              flex-direction: column;

        }
      }
      .btn-item {
        margin-bottom: 3%;
        width: 45%;
        &:first-child {
          margin-right: 25px;
          @media only screen and (max-width: 768px) {
            margin-right: 5%;
          }
        }
        &.btn-purchase{
          margin-right: 0;
          width: 60%;
        }
      }
      .btn-card {
        position: static;
        margin-top: 0;
      }
    }
    .balance-block {
      position: relative;
      margin-top: 10%;
      max-height: 100vh;
      text-align: center;
      font-size: 24px;
      background: linear-gradient(180deg, #202525 0%, #1d1d26 100%);
      border-radius: 12px 12px 0px 0px;
      .price-board {
        max-width: 70%;
        margin: auto;
        top: -13px;
        position: relative;
        .background {
          z-index: 1;
          max-width: 100%;
          padding: 0 12px;
          background: linear-gradient(180deg, #212323 0%, #212121 100%);
          border-radius: 20px;
        }
        .price-container {
          position: absolute;
          width: 100%;
          top: 10%;
          &.top-30 {
            top: 30%;
          }
        }
      }

      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: $color-white;
        font-size: 24px;
        line-height: 36px;
        font-weight: $fw-bold;
        z-index: 100;
      }
      .token-logo {
        width: 36px;
        margin-right: 12px;
      }
    }

    .table-custom {
      margin: 0px 0px;
      color: $color-white;
      font-size: rem(12px) !important;
      width: 100%;
      .ant-table-thead {
        tr {
          th {
            font-weight: $fw-normal;
            font-family: $font-poppins-light;
            overflow: scroll;
            text-overflow: initial;
            &::-webkit-scrollbar {
              display: none;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none;
            }
          }
        }
      }
      .ant-table-cell {
        align-items: flex-end;
        &:not(:last-child) {
          padding-right: 0;
        }
        .img-token{
          width: rem(15px);
          height: rem(15px);
          margin-right: rem(6px);
        }
        
      }
      .value-col {
        font-family: $font-poppins-light;
      }
      .price-col-mobile {
        .offer-tab-icon {
          display: inline-block;
          width: rem(15px);
          object-fit: contain;
          margin-right: rem(6px);
        }
        .from-col {
          color: $color-gray-6;
        }
      }

      .btn-table {
        display: flex;
        .button-item {
          margin-right: 10px;
        }
      }
      &.dna-list {
        .ant-table-tbody > tr > td {
          border: none;
        }
      }
      .ant-table-thead > tr {
        & > th {
          border: none;
        }
        th:first-child {
          padding-left: 30px;
          @media only screen and (max-width: 768px) {
            padding-left: 12px;
          }
        }
      }
      .ant-table-tbody > tr {
        color: $color-gray-text-2;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: $color-table-hover;
          & > td {
            background: $color-table-hover;
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
        th {
          // border-bottom: 1px solid $color-gray-text-2;
          border: none !important;
        }
        td {
          // height: 54px;
          line-height: 27px;
          border-bottom: 1px solid $color-gray-3;
          .from-col {
            // display: block;
            flex-wrap: nowrap;
            white-space: nowrap;
            text-overflow: clip;
            overflow: scroll !important;
            max-width: 90%;
            &::-webkit-scrollbar {
              display: none;
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none;
            }
          }
          &.table-action {
            height: 60px;
            margin-left: auto;
            .btn-accept {
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              text-align: center;
              cursor: pointer;
              width: 83px;
              margin-left: auto;
            }
          }
        }

        td:first-child {
          display: table-cell;
          padding-left: 30px;
          @media only screen and (max-width: 768px) {
            padding-left: 12px;
            padding-right: 0px;
          }
        }
        td:last-child {
          // display: flex;
          padding-right: 0;
          margin-right: 24px;
        }
      }
      .ant-table {
        background: transparent;
        .ant-table-thead > tr {
          th {
            background: #2c3640;
            color: $color-gray-text-2;
          }
          th:first-child {
            border-top-left-radius: 10px;
          }
          th:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }
    .troop-item{
      background: #1C2023;
      border-radius: 12px;
      margin-top: rem(12px);
      padding: rem(30px);
      font: $fw-bold rem(14px) $font-poppins-regular;
      line-height: rem(14px);
      position: relative;

      &-description {
        background: #2E2E2E;
        border-radius: 4px;
        padding: rem(10px) rem(12px);
        color: #E1E2E9;
        font: $fw-normal rem(12px) $font-poppins-regular;
        line-height: rem(18px);
        margin-top: rem(12px);     
      }
      &-image {
        width: rem(128px);
        height: rem(128px);
        background: #141416;
        border-radius: 8px;
      }
      &-rarity{
        width: rem(20px);
        height: rem(20px);
        position: absolute;
        top: 63px;
        left: 133px;
      }
      &-content{
        margin-top: rem(14px);
      }
      .troops-icon{
        img{
          width: rem(24px);
          height: rem(24px);
          margin-right: rem(4px);
        }
        margin: rem(13px) 0;
        align-items: center;
      }
      .troops-cost{
        width: 100%;
        background: #2E2E2E;
        border-radius: 4px;
        padding: rem(7px) rem(12px);
        margin:  0 0 rem(6px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: $fw-normal rem(14px) $font-poppins-regular;
        line-height: rem(21px);     
        img{ 
          width: rem(24px);
          margin-right: rem(4px);
        } 
      }
      .troops-container{
        flex:1;
        .troops-attribute{
          flex:1;
          justify-content: space-between;
        }
      }
    }
    .troops-skill-image{
      width: rem(62px);
      height: rem(62px);
      margin: 0 rem(12px) rem(6px) rem(12px);
    }
    .skill-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: rem(30px) rem(30px) 0;
      .skill-item {
        display: flex;
        margin: rem(10px) 0 rem(39px);
        .skill-image {
          width: 80px;
          height: 80px;
          border-radius: 6px;
          cursor: pointer;
          &-active {
            border: 2px solid #75F94E
          }
        }
        .skill-info {
          .name {
            font-weight: $fw-bold;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 8px;
          }
          .description {
            font-size: 14px;
          }
          @media only screen and (max-width: 768px) {
            .name {
              margin-top: 16px;
            }
          }
        }
      }
    }
    .parent-block {
      display: flex;
      justify-content: space-evenly;
      padding: 14px 8px;
      .nft-card {
        height: auto;
        background: #141416;
        &_header {
          padding: 0px 0.625rem;
        }
        .nft-note{
          padding: 0px 0.625rem;
          color: #cdcedb;
          font-family: $font-poppins-regular;
          font-weight: 400;
          font-size: rem(11px);
          line-height: rem(20px);
          text-align: center;
        }
        .card-mon {
          height: 12.625rem;
        }
      }
    }

    .info-table {
      margin-top: rem(10px);
      color: $color-gray-text-2;
      .ant-tabs-nav::before {
        border-bottom: none;
      }
      .ant-tabs-nav-list {
        justify-content: flex-start;
        .ant-tabs-tab {
          border: none;
          font-size: rem(18px);
          color: $color-white;
          background: transparent !important;
          @media only screen and (max-width: 768px) {
            padding-left: 0;
            padding-right: rem(28px);
            padding-bottom: 0;
          }
          .custom-tab {
            height: 42px;
            .tab-title {
              margin-bottom: rem(13px);
              font-weight: $fw-bold;
            }
          }
          &.ant-tabs-tab-active {
            border-bottom: none !important;
            .ant-tabs-tab-btn {
              color: $color-orange !important;
              .bottom-line {
                height: 1px;
                background: linear-gradient(
                  90deg,
                  rgba(232, 159, 1, 0) 0%,
                  #e89f01 47.4%,
                  rgba(232, 159, 1, 0) 100%
                );
              }
            }
          }
        }
      }
      @media only screen and(max-width: 768px) {
        color: $color-gray-6;
        .stat-name {
          font-size: rem(12px);
        }
        // .stat-value {
        //   color: $color-gray-text-2;
        // }
        .ant-tabs-nav-list {
          width: 100%;
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              .custom-tab {
                .tab-title {
                  font-size: rem(14px);
                }
              }
            }
          }
        }
      }
    }
  }

  .dna-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
  }
  .stat-list {
    display: flex;
    flex-direction: column;
    .troop-content{
      padding-top: rem(22px);
      padding-bottom: rem(8px);
      border-top: 1px solid #131315;
      padding-left: rem(16px);
      padding-right: rem(16px);
      margin-top: rem(22px);
    }
    .current{
        color: #E89F01
      }
    .stat-item {
      display: flex;
      width: 100%;
      padding-left: rem(16px);
      padding-right: rem(16px);
      
      .stat-value {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #131315;
        flex-direction: column;
      }
      .title{
        border: none;
        padding: 8px;
        color: #6C6D76;


      }
      .stat-name {
        padding-bottom: 10px;
      }
      .stat-progress {
        width: 100%;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 0 !important;
      .stat-item {
        // width: 50%;
        margin-bottom: rem(24px);
        // .stat-name {
        //   order: 1;
        //   border-right: none;
        //   padding-bottom: rem(4px);
        // }
        // .square {
        //   order: 2;
        //   margin-right: rem(12px);
        // }
        // .stat-value {
        //   order: 3;
        // }
        // .stat-progress {
        //   display: none;
        // }
      }
    }
  }

  .square {
    width: rem(24px);
    height: rem(24px);
    background: transparent;
    margin-right: rem(8px);
  }

  .offer-list {
    padding: 0 !important;
  }

  .amount-item {
    border: none;
    padding: 0;
    margin: 0;
    input {
      outline: none !important;
    }

    .prefix-input {
      height: 16px;
    }
  }
  .max-button {
    background: $color-orange;
    height: 100%;
    width: 100%;
    border: none;
    font-weight: $fw-bold;
    border-left: 1px solid gray;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: $color-white;
  }

  .amount-row {
    margin-bottom: 0;
    margin-top: 24px;
    color: $color-gray;
    .left-col {
      background: $color-white;
      padding-left: 16px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-top: 2px solid $color-orange;
      padding-top: 5px;
      font-size: 12px;
      line-height: 12px;
      .mb-0 {
        margin-bottom: 0 !important;
        outline: none !important;
      }
    }
  }

  .expire-row {
    &.mt-12 {
      margin-top: 12px !important;
    }
    .left-col {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .expire-select {
      padding-left: 0;

      .ant-select-selector {
        border: none;
        padding-left: 0;
        border-radius: 6px;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .suffix-select {
    position: relative;
    top: -12px;
  }

  .bottom-modal-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .id-modal {
    margin-bottom: 24px;
  }

  .bottom-tag {
    justify-content: center;
    .rarity {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 76px;
      height: 20px;
      background: #9fc5e8;
      border-radius: 10px;
      color: #444551;
      font-size: 10px;
      line-height: 16px;
      margin-right: 8px;
    }
    .race {
      border-radius: 13px;
      padding: 2px 8px;
      background-color: #666666;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      font-family: $font-poppins-regular;
      color: white;
    }
  }

  .attribute-container {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 12px;
    .gray {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #d9d9d9 0%,
        #cfd4d7 100%
      );
      color: #444551;
    }
    .blue {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #9fc5e8 0%,
        #6db2f2 100%
      );
    }
    .yellow {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #ffd966 0%,
        #dfab23 100%
      );
    }
    .red {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #cc4125 0%,
        #9d2c15 100%
      );
    }
    .pink {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #bd6e97 0%,
        #aa4077 100%
      );
    }
    &__rarity,
    &__race {
      border-radius: 13px;
      padding: 2px 8px;
      background-color: #666666;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      font-family: $font-poppins-regular;
      color: white;
    }
    &__rarity {
      margin-right: 8px;
    }
  }

  .d-none {
    display: none;
  }

  .des-item {
    white-space: pre-wrap;
  }

  .max-buy {
    color: $color-yellow-2;
    margin-bottom: 14px;
  }

  .nft-infor {
    display: flex;
    height: rem(24px);
    &__chain{
      width: rem(24px);
      margin-right: rem(8px);
      &-name{
        border-right: 1px solid #6c6d76;
        margin-right: 14px;
        padding-right: 17px;
      }
    }
    .star-container {
      width: auto;
      padding-right: 17px;
      border-right: 1px solid #6c6d76;
      margin-right: 14px;
    }
    .level {
      display: flex;
      color: #cdcedb;
      font: $fw-normal rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      margin-bottom: rem(10px);
      .content {
        font-weight: 600;
        font-size: 18px;
        padding-left: 5px;
        .level-numb {
          color: #e99f01;
          margin: 0 rem(3px);
        }
      }
    }
  }

  

  @media only screen and (max-width: 768px) {
    .nft-infor {
      justify-content: center;
    }
    .left-column {
      padding-bottom: rem(0px) !important;
      .image-block {
        margin-bottom: rem(16px);
      }
      .box-list {
        margin-bottom: rem(71px);
      }
      .name-item {
        font-size: rem(24px) !important;
        margin-bottom: rem(20px) !important;
      }
      .evolution {
        display: block !important;
        text-align: center !important;
      }
      .star-container {
        margin-bottom: rem(24px) !important;
      }
    }
    .row-infor {
      .detail-item {
        margin-top: rem(24px) !important;
      }
    }
  }

  .left-detail {
    // width: 30%;
    .image-block {
      .item-image {
        margin: auto;
        width: 70%;
        height:70%;
        object-fit: contain;
        border: none;
        outline: none;
        &:focus-visible{
          outline: none
        }
      }
      .balance-block {
        margin-top: 7% !important;
        max-height: 45%;
        .btn-block {
          .btn-item {
            max-width: 45%;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) and (min-width: 420px){
      height: 100%;
      .image-block{
        height: 100%;
      }

    }
  }
  
}

.popover-content {
  width: fit-content;
  padding: rem(7px)rem(3px) rem(3px);
  background: linear-gradient(180deg, #393F62 0%, #202338 100%);
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: $fw-bold;
  font-family: $font-poppins-light;
  color: #E1E2E9;
  
  .popover-container{
    font-size: 12px;
    font-weight: $fw-normal;
    font-family: $font-poppins-light;
    color: #E1E2E9;
    width: rem(313px);
    background: #21243A;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 9px;
    padding: rem(14px) rem(8px) rem(8px);
    margin-top: rem(7px);
    &__description {
      padding: rem(12px) rem(20px);
      background: #171929;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
      border-radius: 9px;
      text-align: justify;
    }
    &__right {
      margin-bottom: rem(11px);
      .skill-troop{
        width: rem(20px);
      }
    }
    &__left {
      text-align: center;
      .detail-image{
        width: rem(95px);
        height: rem(95px);
      }
      .detail-attribute{
        width: fit-content;
        margin: rem(6px) auto;
        &__icon{
          width: rem(30px);
          height: rem(30px);
          margin: 0 rem(2px);
        }
      }
    }
    
  }
}



