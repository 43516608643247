@import "../../../styles/variable";

.ranking-game {
  width: rem(310px);
  height: rem(813px);
  background-image: url("../../../assets/images/bg-ranking-game.svg");
  background-size: contain;
  padding: rem(90px) 0;
  position: relative;
  margin-top: rem(-134px);
  background-repeat: no-repeat;

  .ranking-lucky {
    font-family: $font-CookieRun-Bold;
    color: #F0E1C3;
    font-size: rem(14px);
    line-height: rem(21px);
    &-address{
      display: flex;
      flex-direction: column;
      margin-left: rem(20px);
      div{
        margin-bottom: rem(15px);
      }
    }
  }

  .lucky{
    align-items: flex-start !important;
    margin-top: rem(30px);
  }
  .number-reward{
    margin: 0 30px 0 5px !important;
  }

  .lucky-result{
    flex:2;

    .reward{
      margin-bottom: rem(12px);
    }
  }


  .select-season {
    .select-icon{
      width: rem(9px);
    }
    .ant-select-selector{
      background-color: transparent;
      border: none ;
      color: #F0E1C3;
      padding: 0 rem(24px);
      .ant-select-selection-item{
        margin-right: 0;
        font: $fw-normal rem(14px) $font-poppins-regular;
        line-height: rem(21px);
      }
    }
    .ant-select-arrow{
      top: 24%;
    }
    
  }
  .ranking-title-container{
    margin-bottom: rem(25px);
  }

  .ranking-title{
    font: $fw-bold rem(24px) $font-poppins-regular;
    line-height: rem(24px);
    color: #F0E1C3;
    padding: 0 rem(24px);
  }

  .list-ranking{
    max-height: rem(350px);
    overflow-y: scroll;
    height: fit-content;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #F5F5F5;
      border-radius: 1px;
      &-thumb {
        background-color: #EBC276;
        border-radius: 1px;

      }
    } 
  }

  .ranking-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(24px);
    margin-bottom: rem(14px);
    .number-address{
      display: flex;
      font: $fw-bold rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #E1E2E9;
      flex: 3;

      .ranking-number{
        width: rem(24px);
        text-align: center;
        margin-right: rem(8px);
        font-family: $font-CookieRun-Bold;
        color: #F0E1C3;
      }
    }
    .elo-item{
      font: $fw-bold rem(14px) $font-poppins-regular;
      line-height: rem(21px);
      color: #F0E1C3;
      flex: 1;
      text-align: end;

    } 
  }
  .your-ranking{
    position: relative;
    height: rem(70px);
    background: linear-gradient(90deg, rgba(114, 92, 51, 0) 0%, rgba(84, 74, 50, 0.6) 50.23%, rgba(114, 92, 51, 0) 100%) ;
    margin-top: rem(31px);
    &-tag{
      background: #44240E;
      border-radius: 4px;
      padding: rem(4px) rem(9px);
      font: 700 rem(12px) $font-CookieRun-Bold; 
      line-height: rem(18px);
      color: #EBC276;
      position: absolute;
      top: rem(-13px)
    }

    
    .number-address{
      color: #E89F01
    }
  }
  .season-time{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 rem(24px) 0;
    width: 100%;
    margin: 0 auto rem(29px);
    position: relative;

    &__img { 
      width: rem(125px);
      height: rem(85px);
    }

    

    &__text{
      position: absolute;
      font: $fw-normal rem(12px) $font-poppins-regular;
      line-height: rem(18px);
      color: #F0E1C3;
      opacity: 0.5;
      top: rem(10px);
      text-align: center;
      left:0;
      right:0;
      margin-left: auto;
      margin-right: auto;


    }
  }
}
.ranking-modal{
  .ant-modal-content{
    width: rem(642px);
    height: rem(654px);
    border: 1px solid #0F1011;
    box-sizing: border-box;
    box-shadow: inset 0px -4px 0px #0c0e0f, inset 0px 2px 0px #3a4246;
    border-radius: 4px;
    background: radial-gradient(82.93% 82.93% at 50% 17.07%, #3D3933 0%, #1A1714 100%);
    .ant-modal-body{
      height: 100%;
      padding-left: rem(58px);
      padding-right: rem(58px);
      .modal-content{
        height: 100%;
        width: 100%;
        background-image: none;
        margin-top: rem(23px);
        padding-top: rem(20px);
      }

      .ranking-title-container{
        align-items: center;
        justify-content: space-between;
        margin-bottom: rem(25px);

        .ranking-season{
          color: #FEFBF7;
          font: $fw-normal rem(14px) $font-poppins-regular;
          line-height: rem(21px);
          padding-right: rem(24px);
        }
      }
      .reward {
        color: #F0E1C3;
        font:  $fw-normal rem(14px) $font-CookieRun-Regular;
        text-shadow: -1px 0 #522410, 0 1px #522410, 1px 0 #522410,
            0 -1px #522410;
        min-width: rem(110px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex:2;
        img {
          margin-left: rem(50px);
          width: rem(24px);
          height: rem(24px);
        }
      }
    }
  }
}

.modal-reward {
    .ant-modal-content{
      height: rem(490px);
    }

}
@media (max-width: 768px) {
  .ranking-modal{
    width: auto !important;
    .ant-modal-content{
      margin: 0 auto;
    }
    .ant-modal-body{
      padding: 0 !important;
    }
  }
}

@media (max-width: 575px) {
  .ranking-modal{
    .ant-modal-content{
      width: 100%;

      .ant-modal-body .ranking-title-container .ranking-season {
        padding: rem(14px) rem(24px) 0;;
      }
    }
  }

}
