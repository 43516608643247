@import "../../../styles/variable";
 
.bg-banner{
    background-image: url('../../../assets/images/bg-banner-box.png');
    height: calc(100vh - 70px);
    background-size: cover;
    width: 100%;
    position: relative;
    background-position: center;
    .campain-img, .content-campain{
        position: absolute;
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        bottom:0;
        width: rem(1338px);
    }

    .content-campain {
        width: rem(1237px);
        height: rem(217px);
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: rem(31px) rem(76px);
        
        &__left{
            position: relative;
            .sold-out{
                position: absolute;
                width: rem(110px);
                top:rem(-92px); 
                left: rem(-70px); 
            }
        }
        
        .campain{
            &-name{
                font: $fw-normal rem(42px) $font-rammetto-one-regular; 
                line-height: rem(71px);
                color: white;
                text-shadow: 0px 3px 0px #f2b109;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #201B18;                
            }

            &-note{
                display: flex;
                flex-direction: row;
                font: $fw-normal rem(24px) $font-poppins-regular; 
                line-height: rem(37px);
                color: #443115;
                margin-bottom: rem(21px);
            }
            &-price{
                font: $fw-normal rem(32px) $font-poppins-regular; 
                line-height: rem(32px);
                color:#BA6938;
                margin: 0 rem(4px);
            }
        }     
    }

}

@media (max-width: 575px) {
.bg-banner{
    .campain-img {
        width: rem(375px);
    }
    .content-campain {
        flex-direction: column;
        height: rem(288px);
        width: rem(342px);
        padding: rem(30px) rem(25px);
        .campain{
            &-name{
                font-size: rem(24px);
                line-height: rem(40px);

            }
            &-price{
                font-size: rem(18px);
                line-height: rem(21px);
            }
            &-note{
                font-size: rem(14px);
                line-height: rem(16px);
                align-items: flex-end;

            }
        }
        &__left{
            .sold-out{

            }
        }

        
    }

}

}


