@import "../../../../../styles/variable";
.item-box {
  &.checkout-box-item {
    margin-top: rem(12px);
    .rune {
      &.currency-icon {
        width: rem(24px);
        object-fit: contain;
      }
    }
  }
}
.amount-row .ant-form-item-explain-error div{
  font-size: rem(10px);
}

