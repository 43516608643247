@import "../../../styles/variable";
.detail-modal{
  width: 100% !important;
  top: rem(70px);
  height: fit-content;
  min-height: 100%;
  max-width: 100% !important;
  padding-bottom: 0;

  .ant-modal-content {
    height: fit-content;
     .ant-modal-body{
        height: 100vh; 
        background-attachment: fixed;
        background-size: cover;
        background-image: url('../../../assets/images/bg-image.png');
        content: '';
        left: 0;
        position: fixed;
        width: 100%;
        will-change: transform;
        z-index: -1;  
        overflow-y: hidden; 
        .detail-mon{
          overflow: scroll;
          height: 100vh;

        &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none; 
        }
      }
    
    }
      .ant-modal-close{
        top: rem(20px);
        left: rem(70px);
        font: $fw-bold rem(14px) $font-poppins-regular;
        line-height: rem(24px);
        color: white;
        .close-detail{
          display: flex;
          img { 
            margin-right: rem(7px);
          }
        }
      }
  }
}



@media (max-width: 575px) {
  .ant-modal-wrap {
    overflow: hidden;
  }
  .detail-modal{
    top: rem(50px);
   .ant-modal-content {

      .ant-modal-close{
        top: -5px;
        padding-left: 5px;
        left: 0;
        background-color: #13120E;
        &-x{
          line-height: 30px;
          height: fit-content;
        }
      }
      .ant-modal-body {
        min-height:  inherit;
      }
    }
  }

}
