@import "../../../../styles/variable";
 

.box-countdown{
    width: rem(430px);
    height: rem(105px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .time-value {
        font: 500 rem(90px) $font-poppins-regular; 
        line-height: rem(115px);
        color: white;
        text-shadow: 0px 2px 0px #602A1E;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #000000;
    }

    .time-text {
        font : 300 rem(24px) $font-roboto-thin;
        line-height: rem(28px);
        color: #5D4D32;  
        text-align: center;
 
    }

}

@media (max-width: 575px) {
    .box-countdown{
        width: rem(269px);
        height: rem(79px);
        .time-value {
            font: 500 rem(42px) $font-poppins-regular; 
            line-height: rem(60px);
        }
        .time-text{
            font : 300 rem(14px) $font-roboto-thin;
            line-height: rem(16px);

        }
    }
}
