@import "../../../../styles/variable";

.detail-box-container{
  .time-out{
    background: linear-gradient(90deg,rgba(132,26,26,.129),#841a1a 50%,rgba(132,26,26,.132));
    text-align: end;
    font: $fw-bold rem(16px) $font-poppins-regular; 
    line-height: rem(18px); 
    color: white;
    padding: 20px;
  }
  .detail-primary {
    color: $color-gray-text-2;
    // padding-bottom: 100px;
    .col-fixed {
      position: fixed;
      padding-bottom: 100px;

      &.top-0 {
        top: 0;
      }
      &.top-50 {
        top: -20%;
      }
      @media only screen and (max-width: 768px) {
        position: static;
        max-width: 100%;
      }
    }
    .about-list {
      .box-list {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20px);
        @media only screen and (max-width: 768px) {
          gap: rem(6px);
        }
      }
      .box {
        text-align: center;
        width: 138px;
        color: $color-white;
        border-radius: 4px;
        @media only screen and(max-width: 768px) {
          width: rem(106px);
        }
        &.red {
          background-color: $color-red-2;
        }
        &.green {
          background-color: $color-green;
        }
        &.pink {
          background-color: $color-pink;
        }
        &.yellow {
          background-color: $color-yellow;
        }
        &.evolution {
          width: 100%;
          text-align: start;
          margin-bottom: 4px;
          order: 1;
          & > span {
            margin-right: 8px;
          }
        }
        &.rarity {
          order: 2;
        }
        &.race {
          order: 3;
        }
        &.level {
          order: 4;
        }

        &.type {
          order: 5;
        }

        &.other {
          order: 100;
        }
        .box-name {
          height: 24px;
          font-weight: $fw-normal;
        }

        .box-value {
          position: relative;
          height: 48px;
          background: rgba(82, 82, 77, 0.1);
          font-weight: $fw-bold;
          .content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          .box-line {
            position: absolute;
            height: 3px;
            width: 100%;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.8) 0%,
              rgba(20, 20, 31, 0.4) 16%,
              rgba(23, 22, 22, 0.1) 100%
            );
            //background: rgb(83, 71, 71);
            filter: blur(5px);
          }
        }
      }
    }

    .item-box {
      background: $color-black-3;
      border-radius: 12px;
      padding: 14px 16px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      &.disposition-list {
        display: block;
        ul {
          margin-left: 20px;
        }
      }
    }
    .detail-item {
      margin-top: 40px;
      margin-bottom: 14px;
      font-size: 18px;
      font-style: normal;
      font-weight: $fw-bold;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      &.disposition-item {
        margin-bottom: 5px;
      }
    }
    .name-item {
      font-size: 32px;
      font-weight: $fw-bold;
      margin-bottom: 27px;
      color: $color-gray-text;
      &.offer-name {
        font-size: 24px;
        margin-bottom: 8px;
      }
      .id-item {
        font-size: 14px;
        font-weight: $fw-normal;
      }
    }
    .item-image {
      width: 90%;
      max-height: 700px;
      object-fit: contain;
      @media only screen and (max-width: 768px) {
        width: 80%;
      }
    }
    .image-block {
      text-align: center;
    }
    .balance-block {
      position: relative;
      margin-top: 10%;
      max-height: 100vh;
      text-align: center;
      font-size: 24px;
      background: linear-gradient(180deg, #202525 0%, #1d1d26 100%);
      border-radius: 12px 12px 0px 0px;
      .price-board {
        max-width: 70%;
        margin: auto;
        top: -13px;
        position: relative;
        .background {
          z-index: 1;
          max-width: 100%;
          padding: 0 12px;
          background: linear-gradient(180deg, #212323 0%, #212121 100%);
          border-radius: 20px;
        }
        .price-container {
          position: absolute;
          width: 100%;
          top: 10%;
          &.top-30 {
            top: 30%;
          }
        }
      }

      .btn-block {
        margin-top: 30px;
        position: relative;
        z-index: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 30px;
        .btn-item {
          margin-bottom: 3%;
        }
      }
      .price {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: $color-white;
        font-size: 24px;
        line-height: 36px;
        font-weight: $fw-bold;
        z-index: 100;
      }

    }

    .table-custom {
      margin: 0px 0px;
      color: $color-white;
      &.dna-list {
        .ant-table-tbody > tr > td {
          border: none;
        }
      }
      .ant-table-thead > tr {
        & > th {
          border: none;
          @media only screen and (max-width: 768px) {
            padding: 10px;
          }
        }
        th:first-child {
          padding-left: 30px;
        }
      }
      .ant-table-tbody > tr {
        color: $color-gray-text-2;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background: $color-table-hover;
          & > td {
            background: $color-table-hover;
          }
        }
        &:last-child {
          td {
            border: none;
          }
        }
        th {
          // border-bottom: 1px solid $color-gray-text-2;
          border: none !important;
        }
        td {
          // height: 54px;
          line-height: 27px;
          border-bottom: 1px solid $color-gray-3;
          &.table-action {
            height: 60px;
            margin-left: auto;
            .btn-accept {
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              text-align: center;
              cursor: pointer;
              width: 83px;
              margin-left: auto;
            }
          }
        }

        td:first-child {
          // display: block;
          // padding-left: 0;
          // margin-left: 30px;
          display: table-cell;
          padding-left: 30px;
        }
        td:last-child {
          // display: flex;
          padding-right: 0;
          margin-right: 24px;
        }
      }
      .ant-table {
        background: transparent;
        .ant-table-thead > tr {
          th {
            background: #2c3640;
            color: $color-gray-text-2;
          }
          th:first-child {
            border-top-left-radius: 10px;
          }
          th:last-child {
            border-top-right-radius: 10px;
          }
        }
      }
    }
    .skill-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .skill-item {
        display: flex;
        &:not(:first-child) {
          margin-top: 30px;
        }

        .skill-image {
          width: 100%;
        }
        .skill-info {
          .name {
            font-weight: $fw-bold;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 8px;
          }
          .description {
            font-size: 14px;
          }
          @media only screen and (max-width: 768px) {
            .name {
              margin-top: 16px;
            }
          }
        }
      }
    }
    .parent-block {
      display: flex;

      .parent-item {
        width: 200px;
        margin-right: 20px;
        .info {
          text-align: center;
        }
        .profile-img {
          display: block;
          max-width: 80%;
          height: 140px;
          margin: auto;
        }
      }
    }
    .dna-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 !important;
    }
    .stat-list {
      display: flex;
      flex-direction: column;
      .stat-item {
        display: flex;
        width: 100%;
        .stat-value {
          // display: flex;
          // justify-content: center;
        }
        .stat-name {
          padding-bottom: 10px;
          border-right: 1px solid $color-gray-3;
        }
        .stat-progress {
          width: 100%;
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      }
      // @media only screen and (max-width: 768px) {
      //   flex-direction: row;
      //   flex-wrap: wrap;
      //   padding-bottom: 0 !important;
      //   .stat-item {
      //     width: 50%;
      //     margin-bottom: rem(24px);
      //     .stat-name {
      //       order: 1;
      //       border-right: none;
      //       padding-bottom: rem(4px);
      //     }
      //     .square {
      //       order: 2;
      //       margin-right: rem(12px);
      //     }
      //     .stat-value {
      //       order: 3;
      //     }
      //     .stat-progress {
      //       display: none;
      //     }
      //   }
      // }
    }

    .square {
      width: rem(24px);
      height: rem(24px);
      background: transparent;
      margin-right: rem(8px);
    }

    .offer-list {
      padding: 0 !important;
    }

    .amount-item {
      border: none;
      padding: 0;
      margin: 0;
      input {
        outline: none !important;
      }

      .prefix-input {
        height: 16px;
      }
    }
    .max-button {
      background: $color-orange;
      height: 100%;
      width: 100%;
      border: none;
      font-weight: $fw-bold;
      border-left: 1px solid gray;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      color: $color-white;
    }

    .amount-row {
      margin-bottom: 0;
      margin-top: 24px;
      color: $color-gray;
      .left-col {
        background: $color-white;
        padding-left: 16px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-top: 2px solid $color-orange;
        padding-top: 5px;
        font-size: 12px;
        line-height: 12px;
        .mb-0 {
          margin-bottom: 0 !important;
          outline: none !important;
        }
      }
    }

    .expire-row {
      &.mt-12 {
        margin-top: 12px !important;
      }
      .left-col {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .expire-select {
        padding-left: 0;

        .ant-select-selector {
          border: none;
          padding-left: 0;
          border-radius: 6px;
          &:focus {
            outline: none;
          }
        }
      }
    }

    .suffix-select {
      position: relative;
      top: -12px;
    }

    .bottom-modal-btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }

    .id-modal {
      margin-bottom: 24px;
    }

    .bottom-tag {
      justify-content: center;
      .rarity {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 20px;
        background: #9fc5e8;
        border-radius: 10px;
        color: #444551;
        font-size: 10px;
        line-height: 16px;
        margin-right: 8px;
      }
      .race {
        border-radius: 13px;
        padding: 2px 8px;
        background-color: #666666;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        font-family: $font-poppins-regular;
        color: white;
      }
    }

    .rarity-container {
      font-size: rem(24px);
      line-height: rem(32px);
      font-family: $font-rammetto-one-regular;
      -webkit-text-stroke-color: $color-black;
      letter-spacing: -0.05em;
      text-shadow: -0.3px 2px 0px $color-brown-5;
      -webkit-text-stroke-width: 1px;
      &.gray {
        color: #cfd4d7;
      }
      &.blue {
        color: #9fc5e8;
      }
      &.yellow {
        color: $color-yellow-2;
      }
      &.red {
        color: #cc4125;
      }
      .pink {
        color: #bd6e97;
      }
    }

    .d-none {
      display: none;
    }

    .des-item {
      white-space: pre-wrap;
    }

    .max-buy {
      color: $color-yellow-2;
      margin-bottom: 14px;
    }

    // @media only screen and (max-width: 768px) {
    //   .left-column {
    //     padding-bottom: rem(0px) !important;
    //     .image-block {
    //       margin-bottom: rem(16px);
    //     }
    //     .box-list {
    //       margin-bottom: rem(71px);
    //     }
    //     .name-item {
    //       font-size: rem(24px) !important;
    //       margin-bottom: rem(20px) !important;
    //     }
    //     .evolution {
    //       display: block !important;
    //       text-align: center !important;
    //     }
    //     .star-container {
    //       margin-bottom: rem(24px) !important;
    //     }
    //   }
    //   .row-infor {
    //     .detail-item {
    //       margin-top: rem(24px) !important;
    //     }
    //   }
    // }

    .left-detail {
      .image-block {
        @media only screen and (min-width: 1000px) {
          height: 100vh;
        }

        .balance-block {
          margin-top: 7% !important;
          max-height: 45%;
          .btn-block {
            .btn-item {
              max-width: 45%;
            }
          }
        }
      }
      @media only screen and (max-width: 3380px) and (min-width: 2720px) {
        width: 15%;
      }
      @media only screen and (max-width: 2720px) and (min-width: 2200px) {
        width: 20%;
      }
      @media only screen and (max-width: 2220px) and (min-width: 1920px) {
        width: 25%;
      }
      @media only screen and (max-width: 1920px) and (min-width: 1720px) {
        width: 27%;
      }
      @media only screen and (max-width: 1720px) and (min-width: 1210px) {
        width: 35%;
      }
      @media only screen and (max-width: 1210px) and (min-width: 768px) {
        width: 28%;
      }
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }

    .ant-tabs-nav-operations,
    .ant-tabs-nav-operations-hidden {
      position: absolute !important;
      display: none !important;
      width: 0;
    }

    .ant-tabs-top {
      .ant-tabs-nav {
        margin-bottom: 0;
      }
    }

    .about-tab-title {
      background: rgba(217, 217, 217, 0.1);
      border: 1px solid #4D3D31;
      border-radius: 4px;
      padding: rem(6px) rem(12px);
      color: white;
      .egg-icon {
        display: inline-block;
        width: rem(24px);
        margin-right: rem(8px);
      }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none
    }

    .about-tab-content {
      color: #E1E2E9;
      &-name{
        font: $fw-bold rem(32px) $font-poppins-regular; 
        line-height: rem(32px);
        margin: rem(41px) 0;

      }
      &-stat{
        font: $fw-bold rem(24px) $font-poppins-regular; 
        line-height: rem(24px);
        font-weight: 600;
        .stat-title{
          font: $fw-normal rem(14px) $font-poppins-regular; 
          line-height: rem(21px);
          opacity: 0.5;
        }
        img{
          width: rem(30px);
          height: rem(30px);
          margin-right: rem(8px);
        }
        margin-bottom: rem(43px);
      }
      &-note{
        margin: 0 rem(6px) rem(5px);
        color: red;
        font: $fw-normal rem(14px) $font-poppins-regular; 
          line-height: rem(21px);
      }
      .btn-box{
        width: rem(244px);
        .btn-card{
          margin-top: -5px;
          width: 100%;
          .icon-token{
            text-align: right;
          }
          .purchase{
            align-items: flex-start;
          }
        }
      }
    }

    .price-row-primary {
      padding-bottom: rem(26px);
      padding-top: rem(28px);
      align-items: center;
      font-family: $font-poppins-regular;
      font-size: rem(24px);

      background-image: url("../../../../assets/images/price-back.svg");
      font-weight: $fw-bold;
      color: $color-orange;

      .price {
        margin-left: 7%;
        .token-logo {
          display: inline-block;
          margin-right: 10px;
          width: 36px;
          object-fit: contain;
          margin-right: 12px;
        }
      }
      .enough-col {
        color: $color-black;
        font-weight: $fw-normal;
        font-size: rem(14px);
      }
      .btn-item {
        border: 1px solid $color-brown !important;
      }
    }

    .right-primary-col {
      // .ant-tabs-content-holder{
      //   display: none;
      // }
      .table-section {
        .box-description{
            color: #E1E2E9;
            margin-top: rem(40px);
            background-color: #1C2023;
            padding: rem(24px) rem(44px);
            border-radius: rem(12px);
          }

        .custom-tab {
          margin-right: rem(40px);
          
        }
        .ant-tabs-nav-list {
          @media only screen and(max-width: 768px) {
            flex-wrap: wrap;
          }

          .ant-tabs-tab {
            background: transparent;
            border: none;
            padding: 0;
            margin-right: rem(8px);
            margin-top: rem(10px);
            .ant-tabs-tab-btn {
              
            }
            &.ant-tabs-tab-active {
              background: transparent;
              border: none;
              .about-tab-title{
                background: rgba(232, 159, 1, 0.1);
                border: 1px solid #E89F01;
              }
            }
          }
        }
      }
      
      .info-table {
        margin-top: rem(62px);
        .ant-tabs-nav::before {
          border-bottom: none;
        }
        .ant-tabs-nav-list {
          justify-content: flex-start;
          .ant-tabs-tab {
            border: none;
            font-size: rem(18px);
            color: $color-white;
            background: transparent !important;
            .custom-tab {
              height: 42px;
              .tab-title {
                margin-bottom: rem(13px);
                font-weight: $fw-bold;
              }
            }
            &.ant-tabs-tab-active {
              border-bottom: none !important;
              .ant-tabs-tab-btn {
                color: $color-orange !important;
                .bottom-line {
                  height: 1px;
                  background: linear-gradient(
                    90deg,
                    rgba(232, 159, 1, 0) 0%,
                    #e89f01 47.4%,
                    rgba(232, 159, 1, 0) 100%
                  );
                }
              }
            }
          }
        }
        
      }
    }
  }
}

@media only screen and(max-width: 575px) {
  .detail-box-container .detail-primary .right-primary-col .table-section .custom-tab {
    margin-right: rem(20px);
  }
}
