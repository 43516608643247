@import "../../../styles/variable";

.card-game{
  width: rem(311px);
  height: rem(380px);
  background: #1C2023;
  border-radius: rem(9px);
  padding: rem(12px) rem(10px);
  margin: rem(20px) rem(12px) 0 rem(12px) ;
  position: relative;
  .win-treak{
    &-tag{
      position: absolute;
      top: rem(37px);
      z-index: 1;
      width: rem(120px);
      height: rem(38px);
      left: rem(-25px);
    }
    &-text{
      position: absolute;
      top: rem(42px);
      left: rem(-3px);
      color: #FFFFFF;
      font: $fw-normal rem(12px) $font-poppins-regular;
      line-height: rem(18px);
      z-index: 2;
    }
  }

  &__header {
    display: flex;
    font: $fw-normal rem(14px) $font-poppins-regular;
    line-height: rem(21px);
    color: #E1E2E9;
    justify-content: center;
    align-items: center;
    padding: 0 0 rem(11px) rem(4px);
    .elo-text{
      line-height: rem(18px);
      color: #E89F01;
      font-size: rem(12px);
    }
  }

  &__list {
    background:#1C2023;
    width: 100%;
    height: rem(247px);
    border-radius: rem(9px);
    .ant-col{
      width: rem(95px);
      height: rem(122px);
      padding: rem(14px) 0 rem(11px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background: #141416;
      color: #CCCCCC;
      font: $fw-normal rem(10px) $font-poppins-regular;
      line-height: rem(12px);
      margin-right: rem(3px);

      &:nth-child(3) {
        border-top-right-radius: rem(9px);
        margin-right: 0;
      }
      &:nth-child(4) {
        border-bottom-left-radius: rem(9px);
      }
      &:first-child {
        border-top-left-radius: rem(9px);
      }
      &:nth-child(6){
        border-bottom-right-radius: rem(9px);
        margin-right: 0;

      }

      .image-item{
        width: rem(70px);
        height: rem(70px);
      }
    }
    

  }

  .ant-btn[disabled]{
    background: linear-gradient(180deg, #82837E 0%, #525150 100%);
    color: white !important;
    span{
      align-items: center;
      color: white !important;
      font: $fw-bold rem(14px) $font-poppins-regular;
      line-height: rem(14px);
      .time-countdown{
        margin-left: rem(4px);
      }
    }
  }

}