@import "../../../styles/variable";

.my-listing-page {
    flex-flow: nowrap;
    .list-order{
        flex:1;
        padding: 30px;
    }

    .card-footer{
        width: 259px;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 1px solid #31343E;
        
        button {
            width: 50%;
            border: none;
            border-radius: 12px ;    
        }
        .btn-cancel{
            border-right: 1px solid #31343E;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;

        }
    }
    
}
