@import "../../../../../styles/variable";
          
.make-offer-modal{
  .amount-offer .ant-form-item-control .ant-form-item-explain {
    position: absolute;
    bottom: -14px;
    div{
      font-size: rem(10px);
    }
  }
  .max-balance{
    font: $fw-normal rem(12px) $font-poppins-regular; 
    line-height: rem(18px);
    margin-top: rem(12px);
  }

  .row-infor .right-col .ant-select-arrow{
    top: 53%;
     .select-icon{
       width: 10px;
     }
    
  }
}