@import "../../../../styles/variable";

.make-offer-modal {
  width: 600px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .wallet-box-line {
    box-shadow: -6px 8px 5px 1px rgba(0, 0, 0, 0.2);
    height: 1px;
  }
  .ant-modal-content {
    width: 600px;
    .ant-modal-body .title-popup {
      font-size: rem(25px);
      line-height: rem(21px);
      font-family: $font-poppins-bold;
      padding-bottom: rem(24px);
      letter-spacing: -0.05em;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #201b18;
      -webkit-text-fill-color: $color-white;
      font-weight: $fw-bolder;
    }
  }
  .row-infor {
    color: $color-gray-text-2;
    .right-col {
      .ant-select-arrow {
        top: 42%;
      }
      .title-select {
        position: absolute;
        top: 5px;
        left: 12px;
        z-index: 2;
        font-size: 12px;
      }
      .ant-select-selection-item {
        margin-top: rem(15px);
        display: flex;
        align-items: center;
        img {
          width: rem(20px);
          height: rem(20px);
          margin-right: rem(7px);
        }
      }
      .select-token {
        width: 100%;
        height: 58px;
        border-radius: 0;
        border-top: 2px solid #e89f01;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        .ant-select-selector {
          height: 100%;
          align-items: center;
        }
      }
    }
    .btn-block {
      margin-top: 6%;
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      .btn-popup {
        margin-bottom: 24px;
      }
    }
    .right-column {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .ant-select-dropdown {
    .ant-select-item-option-content {
      .token-icon {
        width: rem(20px);
        height: rem(20px);
        margin-right: rem(7px);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    .ant-modal-content {
      width: 100% !important;
      .ant-modal-body {
        width: 100% !important;
        background: $color-black;
        .offer-name,
        .id-item {
          text-align: center;
        }

        .offer-modal-box {
          background: $color-black-3;
        }
      }
    }
  }

  .row-infor .modal-image {
    width: 100%;
    margin-right: 10%;
    @media only screen and (max-width: 769px) and (min-width: 568px) {
      width: 73%;
      margin: auto;
    }
  }

  .offer-modal-box {
    flex-direction: column !important;
    .info-item {
      width: 100%;
      justify-content: space-between;
      .value {
        &.d-block {
          display: block;
          text-align: right;
          word-break: break-word;
          max-width: 80%;
          word-wrap: break-word;
        }
        .rune {
          height: 60%;
          max-height: 24px;
          margin-right: 8px;
          object-fit: contain;
        }
      }
    }
  }

  .bottom-modal-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px !important;

    &.mt-68 {
      margin-top: 68px !important;
    }
  }

  .btn-accept {
    padding: 6px;
    margin-right: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
  }

  .received-mon {
    color: rgb(228, 208, 29) !important;
  }

  .modal-item-container {
    margin-bottom: 8px;
  }

  .bridge-network {
    display: flex;
    gap: 4px;

    .title {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-right: rem(4px);
    }
    
    .ant-select-selector {
      background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
      color: white;
      border: 1px solid #2e303e;
      box-sizing: border-box;
      border-radius: rem(6px);
      // img {
      //     margin-right: rem(8px);
      //     width: rem(20px);
      //     height: rem(20px);
      // }
    }
  }

  .receive-note {
    margin-top: 8px;
  }

  .estimated-amount {
    margin-top: rem(18px);
    .amount-value {
      margin-left: rem(14px);
      color: $color-orange;
    }
  }

  @media only screen and (max-width: 768px) {
    .make-offer-modal {
      margin: 0 !important;
      &.general {
        width: 100vw !important;
        max-width: 100vw !important;
        position: fixed;
        top: 0;
        .ant-modal {
          &-content {
            width: 100% !important;
            height: 100vh !important;
            .ant-modal-close {
              .close-icon {
                display: none;
              }
            }
          }
          &-body {
            height: 100vh;
            .offer-info {
              height: 60vh;
              justify-content: center;
              .left-column {
                justify-content: center;
                max-height: rem(180px);
                .item-image {
                  display: flex;
                  // height: rem(180px);
                }
              }

              .rarity-box {
                justify-content: center;
              }
            }
            .offer-name,
            .id-modal {
              text-align: center;
            }
            .btn-block {
              height: 48px;
              margin-bottom: 20%;
              align-self: end;
            }
            .mt-68 {
              margin-top: rem(168px);
            }
          }
        }
      }
    }
  }

  .rarity-box {
    display: flex;
    .attribute-container__rarity {
      display: flex;
      margin-top: rem(8px);
      border-radius: rem(13px);
      padding: rem(2px) rem(8px);
      background-color: #666666;
      font-weight: normal;
      font-size: rem(10px);
      line-height: rem(16px);
      font-family: $font-poppins-regular;
      color: white;
      &.gray {
        background: radial-gradient(
          82% 82% at 50% 16.67%,
          #d9d9d9 0%,
          #cfd4d7 100%
        );
      }
      &.blue {
        background: radial-gradient(
          82% 82% at 50% 16.67%,
          #9fc5e8 0%,
          #6db2f2 100%
        );
      }
      &.yellow {
        background: radial-gradient(
          82% 82% at 50% 16.67%,
          #ffd966 0%,
          #dfab23 100%
        );
      }
      &.red {
        background: radial-gradient(
          82% 82% at 50% 16.67%,
          #cc4125 0%,
          #9d2c15 100%
        );
      }
      &.pink {
        background: radial-gradient(
          82% 82% at 50% 16.67%,
          #bd6e97 0%,
          #aa4077 100%
        );
      }
    }
  }
}
