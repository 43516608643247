.item-box {
  margin-top: 10px;
  margin-bottom: 10px;
  .info-item{
    flex-direction: column; 
    .chain-image{
      margin-top: 17px !important;
      img{
        margin-right: 8px;
      }
    }
    &.quantity{
      margin-top: 8px;
    }
}

}