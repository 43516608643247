@import "../../../../styles/variable";

.user-logged-block {
    display: flex;
    align-items: center;
    justify-self: center;
    .user-notification{
        margin-right: 10px;
    }
    .ant-menu-title-content {
        display: flex;
        align-items: center;
    }
    .balance-block {
        font-family: $font-rammetto-one-regular;
        margin-right: 55px;
        font-size: 12px;
        line-height: 21px;
        background: linear-gradient(180deg, #101014 0%, #181E1D 100%);
        border: 0.5px solid #5E3B22;
        border-radius: 6px;
        min-width: 100px;
        width: fit-content;
        height: 30px;
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 12px;
        position: relative;
        padding-right: 20px;
        
        .logo-token {
            position: absolute;
            right: -10px;
            width:28px
        }
    }
    .address-block {
        background: linear-gradient(180deg, #24242D 0%, rgba(36, 36, 45, 0) 100%);
        border: 1px solid #2E303E;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 0px 16px;
        width: rem(114px);
        height: rem(36px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #a4a5af;
    }

    .chain-block{
        justify-content: center;
        width: fit-content;
        margin-right: rem(24px);
        height: rem(39px);

        img{
            width: rem(24px);
            margin-right: rem(8px);
        }
    }
}

.dropdown-menu-login{
    width: rem(224px);
    background: #181515;
    border-radius: 10px;
    border: rem(1px) solid transparent;
    color: white;
    padding: 0;
    background:
                linear-gradient(#181515,#181515) padding-box,
                linear-gradient(to top, transparent 0%, #FFA919 100%) border-box;
        



    .copy-address {
        color: #E1E2E9 !important;
        background: #322B2B;
        border-radius: 10px 10px 0px 0px;
        img{
            margin-left: rem(8px);
        }
    }
    .item-text{
        color: #E1E2E9 !important;
        background: #322B2B;
        border-radius: 0px 0px 10px 10px ;
    }
    .balance-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        &__icon{
            width: rem(20px); 
            margin-right: rem(4px);
        }
    }
    .ant-dropdown-menu-item{
        padding: rem(14px);
        &:hover{
            background-color: #A4A5AF !important;
        }
        a{
            color: #E1E2E9;
        }
        &-divider{
            margin: 0;
            background: linear-gradient(180deg, rgba(30, 30, 37, 0) 0%, #474D55 48.44%, rgba(29, 29, 35, 0) 100%);
        } 
        .logo-token{
            margin-right: rem(8px);
            width: rem(24px);
        }
    }
}

@media (max-width: 768px) {
    .user-logged-block .balance-block {
        margin-right: rem(30px);
    }
}

