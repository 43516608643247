@import "../../../styles/variable";

.filter-block {
  .btn-block {
    display: flex;
    justify-content: center;
}
}
.collection-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: rem(36px);
    
    .dropdown-collection {
        height: 100%;
        max-width: rem(222px);
        color: #a4a5af;
        .ant-select-selector {
            background: linear-gradient(180deg, #101014 0%, #181e1d 100%);
            border: 1px solid #2e303e;
            box-sizing: border-box;
            border-radius: rem(6px);
            img {
                margin-right: rem(8px);
                width: rem(20px);
                height: rem(20px);
            }
        }
        .ant-select-arrow {
            color: #e1e2e9;
        }
    }
    .filter-mobile {
        height: 100%;
        padding: rem(6px) rem(13px);
        color: #e1e2e9;
        font: $fw-normal rem(18px) $font-poppins-regular;
        line-height: rem(21px);

        img {
            margin-right: rem(8px);
        }
    }
}

.btn-bundle {
    margin-top: rem(18px);
    margin-right: 15px;
    @media only screen and (max-width: 576px) {
        position: static !important;
        text-align: center;
    }
}

.drawer-filter {
    padding: 0;
    .ant-drawer-content-wrapper {
        width: rem(258px) !important;
    }
    .ant-drawer-body {
        padding: 0;
        background: linear-gradient(180deg, #31313d 0%, #0c0c0d 100%);
        box-sizing: border-box;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
        .sort-filter {
            width: 100%;
            .container-sort {
                padding: rem(31px) rem(23px);
                height: 90vh;
            }
        }
    }
}
.close-filter {
    position: absolute;
    width: rem(32px);
    top: 0;
    right: rem(241px);
    z-index: 10000;
}

.ant-select-dropdown {
    background: linear-gradient(180deg, #31313d 0%, #0c0c0d 100%);
    border: 1px solid #0f1011;
    box-sizing: border-box;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: rem(4px);
    padding: rem(12px);

    .ant-select-item {
        padding: rem(12px) 0;
        border-bottom: 1px solid #262526;
        font: $fw-normal rem(14px) $font-poppins-regular;
        line-height: rem(21px);
        color: #a4a5af;

        img {
            margin-right: rem(8px);
            width: rem(20px);
            height: rem(20px);
        }
        &-option {
            &-selected,
            &-active {
                background: none;
                color: #e89f01;
            }
        }
    }
}
