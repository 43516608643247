@import "../../../styles/variable";

.inventory-page {
  
  .ant-tabs-ink-bar {
        background: linear-gradient(90deg, rgba(232, 159, 1, 0) 0%, #E89F01 47.4%, rgba(232, 159, 1, 0) 100%);
  }

  .ant-tabs-top > .ant-tabs-nav::before {
      border: none;
  }
  .ant-tabs-tab{
      margin: 0 30px 0 0;
      img{
          width: rem(20px);
          height: rem(20px);
          margin-right: rem(5px);
        }
      
      &-btn {
          color: #E1E2E9;
          opacity: 0.5;
      }
      &-active .ant-tabs-tab-btn {
          color: #E1E2E9;
          opacity: 1;
      }
  }
  .inventory-content-tab{
    flex: 1;
    .sort-filter {
      margin-top: 37px;
      margin-right: 25px;
    }
    .inventory-wall{
      width: 100%;
      position: relative;
      &.display-filter {
        .ant-tabs-tab{
          &:first-child{
            display: none;
          }
        }
      }
      .btn-filter{
        position: absolute;
        height: rem(40px);
        margin-top: rem(32px);
        font: $fw-normal rem(18px) $font-poppins-regular; 
        line-height: rem(21px);
        img{
          margin-right: 4px;
        }
      }
      .collection-name {
        display: flex;
        color: #A4A5AF;
        font: $fw-bold rem(24px) $font-poppins-regular; 
        line-height: rem(36px);
        align-items: center;
        padding-left: rem(25px);
        div{
          color: #6C6D76;
          font: $fw-normal rem(12px) $font-poppins-regular; 
          line-height: rem(18px);
          margin-left: rem(12px);
        }
      }
      .btn-create{
        position: absolute;
        top: rem(100px);
        right: 0;
      }
      .select-txt {
        position: absolute;
        top: rem(108px);
        right: 340px;
        color: #FFFBFB;
        font: $fw-bold rem(14px) $font-poppins-bold; 
      }
      .list-item .btn-container {
        justify-content: center;
        align-items: center;
        .ant-col{
          padding: 0 rem(2px) !important;
          .btn-card{
            width: 95%;
            margin-top: 0;
          }
        }
        
      }
      .btn-convert{
        position: absolute;
        top: rem(100px);
        right: 170px;
        &.brown {
          background: linear-gradient(180deg, #BE9C80 0%, #68492A 100%);
          box-shadow: 0px 4px 4px #68492ab3;
        }
        &.red {
          background: linear-gradient(180deg, #D55050 0%, #A43535 100%);
          box-shadow: 0px 4px 4px #a43535b4;
        }
      }
      .ant-tabs-tabpane{
        flex:1;
        .list-item {
            .ant-col{
                .nft-card{
                  margin-left: 0
                }
            } 
        } 

      }
      
    }
  }
  .ant-checkbox-inner {
    background: #DFCBA3;
    border: 1px solid #5F3824;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 10px;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(180deg, #F9C744 0%, #DF8C00 100%);
    border: 1px solid #5F3824;
    box-sizing: border-box;
    border-radius: 2px;
  }
  .ant-checkbox-checked::after{
    border: none;
  }
  .w-244{
    width: 244px;
  }
  .ml-91{
    margin-left: 91px;
  }
}

.dropdown-create-bundle{
  .chain-item{
    padding: rem(16px);
    background: #35322D;
    border-bottom: 1px solid black;
    font: $fw-normal rem(18px) $font-poppins-regular; 
    line-height: rem(18px);
    color: white !important;
    &__image{
      width: rem(24px);
      margin-right: rem(17px);
    }
  }
}