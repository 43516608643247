@import "../../../styles/variable";

.ant-dropdown-menu {
    @include themed() {
        background-color: t("bg-card");
        color: t("text");
    }
    .ant-dropdown-menu-item {
        @include themed() {
            color: t("text");
        }
    }
    .social-icon {
        img {
            @include themed() {
                filter: t("filter-svg");
            }
        }
    }
}
