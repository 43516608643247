@import "../../../styles/variable";

.my-deck {
  text-align: center;
  width: 100%;
  padding-top: 29px;

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
      height: rem(32px) !important;
      align-items: center !important;
  }

  .tab-deck{
    .ant-tabs-nav{
      width: fit-content;
      margin: 0 auto;
      background: #E89F01;
      border: rem(2px) solid #5F3824;
      box-sizing: border-box;
      border-radius: rem(10px);
      padding: rem(4px);
      font: $fw-bold rem(18px) $font-poppins-regular;
      color: #44240E;
    }
    
    .ant-tabs-tab {
      padding: 0;
      margin: 0;
      width: 111px;
      justify-content: center;
    }
    .ant-tabs-nav-list{
      height: 100%;
    }
    .ant-tabs-ink-bar{
      display: none;
    }
    .ant-tabs-tab-btn {
      font: $fw-bold rem(18px) $font-poppins-regular;
      color: #44240E;
      opacity: 0.5; 
    }
    .ant-tabs-tab-active {
      opacity: 1;
      background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%) !important;
      // box-shadow: -2px 1px 6px rgb(0 0 0 / 25%);
      border-radius: 7px;
      height: 100%;
    }

    

  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within{
      box-shadow: none !important;
  }

  .ant-radio-group{
    background: #E89F01;
    border: rem(2px) solid #5F3824;
    box-sizing: border-box;
    border-radius: rem(10px);
    padding: rem(4px);

    .ant-radio-button-wrapper-checked {
      border-right-color: #40a9ff;
      background: linear-gradient(180deg, #FFFFFF 0%, #E8EAEA 100%) !important;
      // box-shadow: -2px 1px 6px rgb(0 0 0 / 25%);
      border-radius: 7px;
      border-color: transparent;
      span {
        color: #44240E !important;
        opacity: 1 !important;  
      }
    }
    
    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }

    .ant-radio-button-wrapper {
      background: transparent;
      border: none;
      span{
        font: $fw-bold rem(18px) $font-poppins-regular;
        color: #44240E;
        opacity: 0.5;  
      }
    }
  }
}


