@import "../../../styles/variable";

.nft-card {
  width: rem(244px);
  height: rem(380px);
  border-radius: rem(9px);
  margin: rem(4px) 0 rem(20px);
  position: relative;
  cursor: pointer;
  border: 1px solid #3D3D40;
   
  &.no-footer {
    height: rem(300px);
  }
  &:hover {
    @include themed() {
      box-shadow: t("box-shadow");
    }
  }
  @include themed() {
    background-color: t("bg-card");
  }

  .offer-expired {
    position: absolute;
    top: rem(77px);
    left: rem(-8px);
    z-index: 2;
    &-text {
      position: absolute;
      top: rem(78px);
      left: rem(5px);
      z-index: 3;
      color: #ffffff;
      font: $fw-normal rem(12px) $font-poppins-regular;
      line-height: rem(18px);
    }
  }

  .card-decor__image{
    width: rem(160px);
    height: rem(160px);
    margin: 0 auto;
  }
  .card-decor__bundle{
    width: rem(100px);
    height: rem(100px);
    margin: 0 auto;
  }

  .card-mon {
    width: 100%;
    position: relative;
    background: #141416;
    border-radius: rem(9px);
    padding: rem(12px);
    height: rem(260px);
    text-align: center;
    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      .rarity-race {
        display: flex;
        flex-direction: column;

        &__rarity {
          height: rem(24px);
          margin-bottom: rem(5px);
        }
      }
      .level-children {
        .level {
          display: flex;
          color: #cdcedb;
          font: $fw-normal rem(14px) $font-poppins-regular;
          line-height: rem(21px);
          margin-bottom: rem(10px);
          div {
            color: #e99f01;
            margin: 0 rem(3px);
          }
        }
        .star-container {
          span {
            display: flex !important;
            flex-direction: column;
            img {
              margin-bottom: rem(5px);
            }
          }
        }
      }

      .star-container {
        width: fit-content;
      }
    }
    &__image {
      position: absolute;
      max-height: rem(160px);
      text-align: center;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: rem(55px);
      z-index: 1;
      max-width: 80%;
      &.price{
        top:rem(35px)
      }
    }

   

    &__attributes {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: rem(5px);
      div {
        margin: 0 rem(4px) 0;
        text-align: center;
        color: #ffffff;
        font: $fw-normal rem(11px) $font-poppins-regular;
        line-height: rem(18px);
        display: flex;
      }
      img {
        width: rem(15px);
        margin-right: rem(2px);
      }
    }
  }

  &_price {
    position: absolute;
    bottom: 12px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: fit-content;
    color: white;
    font-weight: 600;
    .price{
      font-weight: 600;
      font-size: rem(24px);
      line-height: rem(24px);
      img{
        width: rem(16px);
        margin-left: rem(-3px);
      }
    }
    
    .purchase {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: rem(12px);
      color: #5D6588;
      width: 100%;
      justify-content: center;
      &__icon{
        line-height: rem(4px);
        margin-right: rem(2px);
      }
    }
  }

  &_header {
    width: 100%;
    color: #e1e2e9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(12px);
    padding: rem(9px) rem(12px);

    &-name {
      font-family: $font-poppins-regular;
      font-weight: 400;
      font-size: rem(12px);
      line-height: rem(20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .attributes-children {
        bottom: rem(7px);
        margin: 0 auto;
        left: 0;
        right: 0;
        color: #cccccc;
        font: $fw-normal rem(10px) $font-poppins-regular;
        line-height: rem(14px);
        .land-size {
          font: $fw-normal rem(14px) $font-poppins-regular;
          line-height: rem(21px);
          display: flex;
          flex-direction: row;
          &__value {
            color: #e99f01;
            margin-left: rem(4px);
          }
        }
      }
    }
    &-id {
      font-family: $font-poppins-regular;
      font-weight: 400;
      font-size: rem(12px);
    }
  }
  &_btn{
    width: 100%;
    padding: 0 rem(12px);
    position: absolute;
    bottom: 8px;
  }
}

.card-bun-container {
  margin: 0 auto;
  display: flex;
  height: rem(260px);
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  background-color: #141416;
  align-items: center;
  border-radius: rem(9px) rem(9px) 0 0;

  .nft-card_image {
    width: 40%;
    height: 40%;
    background: #141416;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: rem(90px);
      margin: auto 0;
    }
  }
  .more-items {
    width: 40%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .border-adius-6px-left {
    border-top-left-radius: rem(6px);
    border-bottom-left-radius: rem(6px);
  }
  .border-adius-6px-right {
    border-top-right-radius: rem(6px);
    border-bottom-right-radius: rem(6px);
  }
  .border-adius-2px-left {
    border-top-left-radius: rem(2px);
    border-bottom-left-radius: rem(2px);
  }
  .border-adius-2px-right {
    border-top-right-radius: rem(2px);
    border-bottom-right-radius: rem(2px);
  }

  .full-height {
    height: 100%;
    width: 50%;
    background: #141416;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: rem(9px) rem(9px) 0 0 ;

    img {
      border-radius: 0;
      background: none;
      width: 80%;
      margin: auto 0;
    }
  }
}

.bundle-id {
  text-align: right !important;
  margin-bottom: rem(26px);
}

.header-title {
  display: flex;
  justify-content: space-between;
  .nft-card_name {
    margin-top: 0 !important;
    font-size: rem(18px);
    line-height: rem(18px);
    font-weight: $fw-bold;
  }
}

.item-box {
  background: $color-black-3;
  border-radius: rem(12px);
  padding: 14px 16px;
}

@media (max-width: 575px) {
  .nft-card {
    width: rem(343px);
    margin: rem(24px) 0 0;
    .card-bun-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 890px) {
  .nft-card {
    width: rem(320px);
    margin: rem(24px) 0 0;
    .card-bun-container {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: 1920px) {
  .nft-card {
    width: rem(292px);
  }
}

.nft-card_img {
  width: rem(30px) !important;
  height: rem(30px) !important;
}