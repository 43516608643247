@import "../../../styles/variable";

.rarity-block {
  .box {
    text-align: center;
    width: rem(138px);
    color: $color-white;
    border-radius: rem(4px);
    margin-right: rem(20px);
    @media only screen and(max-width: 768px) {
      width: rem(106px);
    }
    &.red {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #cc4125 0%,
        #9d2c15 100%
      );
      order: 6;
    }
    &.green {
      background: $color-green;
      order: 7;
    }
    &.blue {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #9fc5e8 0%,
        #6db2f2 100%
      );
      order: 8;
    }
    &.pink {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #bd6e97 0%,
        #aa4077 100%
      );
      order: 5;
    }
    &.yellow {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #ffd966 0%,
        #dfab23 100%
      );
    }
    &.silver {
      background: radial-gradient(
        82% 82% at 50% 16.67%,
        #d9d9d9 0%,
        #cfd4d7 100%
      );
    }
    .box-name {
      height: 24px;
      font-weight: $fw-normal;
    }

    .box-value {
      position: relative;
      height: 48px;
      background: rgba(82, 82, 77, 0.1);
      font-weight: $fw-bold;
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      .box-line {
        position: absolute;
        height: 3px;
        width: 100%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(20, 20, 31, 0.4) 16%,
          rgba(23, 22, 22, 0.1) 100%
        );
        //background: rgb(83, 71, 71);
        filter: blur(5px);
      }
    }
  }
}
