$font-poppins-medium: "Poppins-Medium";
$font-poppins-semibold: "Poppins-SemiBold";
$font-poppins-regular: "Poppins-Regular";
$font-poppins-bold: "Poppins-Bold";
$font-poppins-italic: "Poppins-Italic";
$font-rammetto-one-regular: "Rammetto One-Regular";
$font-roboto-thin: "Roboto-Thin";
$font-poppins-light: "Poppins-Light";
$font-CookieRun-Bold: "CookieRun Bold";
$font-CookieRun-Regular: "CookieRun Regular";

@font-face {
  font-family: $font-rammetto-one-regular;
  src: url("../assets/fonts/RammettoOne-Regular.ttf");
}

@font-face {
  font-family: $font-poppins-medium;
  src: url("../assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: $font-poppins-semibold;
  src: url("../assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: $font-poppins-bold;
  src: url("../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: $font-poppins-regular;
  src: url("../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: $font-poppins-italic;
  src: url("../assets/fonts/Poppins-Italic.ttf");
}

@font-face {
  font-family: $font-roboto-thin;
  src: url("../assets/fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: $font-poppins-light;
  src: url("../assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: $font-CookieRun-Bold;
  src: url("../assets/fonts/CookieRun Bold.otf");
}

@font-face {
  font-family: $font-CookieRun-Regular;
  src: url("../assets/fonts/CookieRun Regular.otf");
}

$base-size: 16px;
@function rem($size, $context: $base-size) {
  @return $size / $base-size * 1rem;
}

$themes: (
  light: (
    bg: white,
    text: black,
    box-shadow: 0px 6px 30px rgba(161, 170, 180, 0.425),
    bg-card: #1c2023,
    border-card: 1px solid #31343e,
  ),
  dark: (
    bg: #0e0e11,
    text: #a4a5af,
    box-shadow: 0px 6px 30px rgba(3, 3, 3, 0.16),
    bg-card: #1c2023,
    border-card: 1px solid #31343e,
    bg-filter: linear-gradient(180deg, #1c2023 0%, rgba(114, 99, 51, 0) 100%),
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
$fw-normal: 400;
$fw-bold: 600;
$fw-bolder: 800;

$color-red: #dc3a61;
$color-red-2: #cc4125;
$color-white: #ffffff;
$color-black: #000000;
$color-black-2: #201b18;
$color-brown: #666666;
$color-gray: #999999;
$color-gray-text: #a4a5af;
$color-gray-text-2: #e1e2e9;
$color-gray-text-3: #c4c4c4;
$color-line: #e4e4e4;
$color-bg: #eeeeee;
$color-gray-bg: #f3f3f3;
$color-light-gray-bg: #f4f4f4;
$color-gray2: #807878;
$color-gray-3: #474d55;
$color-gray-4: #1c2023;
$color-gray-5: #989181;
$color-gray-6: #6c6d76;
$color-gray-7: #4a4b53;
$color-green: #adb941;
$color-orange: #e89f01;
$color-pink: #bd6e97;
$color-yellow: #dfab23;
$color-yellow-2: #ffd966;
$color-brown: #5f3824;
$color-brown-2: #675b52;
$color-black-3: #1c1914;
$color-brown-4: #905722;
$color-brown-5: #42210b;
$color-yellow-btn: linear-gradient(180deg, #ffae19 0%, #db9310 100%);
$color-green-btn: linear-gradient(180deg, #c9d550 0%, #98a435 100%);
$color-red-btn: linear-gradient(180deg, #d55050 0%, #a43535 100%);
$color-orange-btn: linear-gradient(180deg, #f9c744 0%, #df8c00 100%);
$color-brown-btn: linear-gradient(180deg, #be9c80 0%, #68492a 100%);

$color-table-hover: linear-gradient(
  90deg,
  #46412b 0%,
  #46412b 48.44%,
  #46412b 100%
);
