@import "../../../styles/variable";
 
.bg-banner{
    background-image: url('../../../assets/images/bg-box-devil.png');
    height: calc(100vh - 70px);
    background-size: cover;
    width: 100%;
    position: relative;
    background-position: center;
    align-items: center;
    display: flex;
    .box-content {
        font: 900 rem(58px) $font-CookieRun-Bold; 
        line-height: rem(75px);
        color: #EBC276;
        height: fit-content;
        margin: auto rem(85px);
        &-time{
            font: $fw-normal rem(18px) $font-CookieRun-Regular; 
            line-height: rem(24px);
            color: white;
            margin: rem(12px) 0 rem(43px);
        } 
        &-btn{
            margin-top: rem(40px);
            font-family: $font-poppins-regular; 
            width: rem(140px);
        }
        .box-table{
            max-width: rem(588px);
            .ant-table {
                background: transparent;
                font: $fw-normal rem(12px) $font-poppins-regular; 
                line-height: rem(18px);
                .token-image{
                    width: rem(20px);
                    height: rem(20px);
                }
                .ant-table-thead>tr th {
                    background: rgba(54, 52, 55, 0.7);
                    color:#FFFFFF;
                    border-bottom: none;
                    font-weight: 400;
                    &:last-child{
                        border-top-right-radius: 12px;
                    }
                    &:first-child{
                        border-top-left-radius: 12px;
                    }
                }
                .ant-table-tbody>tr{
                    background: rgba(54, 52, 55, 0.5);
                    color: white;
                    &:hover{
                        background: rgba(54, 52, 55, 0.5);
                        color: white;
                        td {
                            background: rgba(54, 52, 55, 0.5);
                            color: white; 
                            font-weight: 500;

                        }
                    }
                    td {
                        border-bottom: none;
                    }
                    &:last-child{
                        td{
                            &:last-child{
                                border-bottom-right-radius: 12px;
                            }
                            &:first-child{
                                border-bottom-left-radius: 12px;
                            }
                        }
                        
                    }
                }
            }
        }
    }

}

@media (max-width: 575px) {
    .bg-banner{
        width: 100%;
        .box-content{
            margin: auto 0;
            padding: 0 10px;
            font: 900 rem(30px) $font-CookieRun-Bold; 
            line-height: rem(35px);
            &-time{
                font: $fw-normal rem(12px) $font-CookieRun-Regular; 
                line-height: rem(16px);
            }
            .ant-table-tbody > tr > td{
                padding: 16px 6px;
            }
            .ant-table .ant-table-thead > tr th {
                padding: 8px 6px;
            }
        }

    }
}


