@import "../../../styles/variable";

.rarity-container {
    display: flex;
}

.stat-item{
    .square-icon{
        display: block;
        width: 100%;
    }
    .ant-slider-track{
        background: #A4A5AF !important;

    }
    .ant-slider-rail {
        background: #A4A5AF;
        opacity: 0.2;
    }
    .ant-slider-handle {
        width: 0;
        height: 0;
        border: none;
    }
    .troop-container{
        display: flex;
        flex-direction: row;
        padding-left: 20px;
    }
    
    .troop-icon {
        justify-content: center;
        align-items: center;
        display: flex;
        img{
            width: rem(24px);
            margin-left: rem(5px);
        }
    }
    .race-image{
        width: rem(20px);
        margin-right: rem(10px);
    }
    .food-container{
        display: flex;
        flex-direction: row;
        margin-bottom: rem(4px);
        min-width: rem(80px);
        justify-content: space-between;
        text-align: left;
        .food-image{
            width: rem(20px);
        }
    }
    .plot-stat{
        align-items: flex-start !important;
        padding-left: rem(20px);
    }
}


