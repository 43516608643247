@import "../../../styles/variable";

.game-content {
  .ant-tabs-top > .ant-tabs-nav::before {
        border: none;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    height: 37px;
    align-items: flex-end;
  }

  .ant-tabs-nav {
    padding-top: rem(42px);
    margin-bottom: 0;
  }

  .ant-tabs-tab{
    margin: 0 30px 0 0;
    font: $fw-bold rem(18px) $font-CookieRun-Bold; 
    line-height: rem(30px);

    &-btn {
        color: #E1E2E9;
        opacity: 0.5;
    }
    &-active .ant-tabs-tab-btn {
        color: #EBC276;
        opacity: 1;
    }
  }

  .ant-tabs-ink-bar {
    background: linear-gradient(90deg,rgba(232,159,1,0),#e89f01 47.4%,rgba(232,159,1,0));
  }

  .home-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .list-competitor{
      width: rem(1050px);
      padding: rem(49px) 0;

    }
  }
}

@media (max-width: 1222px) {
  .game-content .home-container{
    .list-competitor{
      justify-content: center;
      padding-top: rem(90px);
    }
  }

}

@media (max-width: 575px) {
  .game-content .home-container .list-competitor{
    padding-top: rem(190px);
  }
}

