@import "../../../../styles/variable";
.bundle-detail {
  position: relative;
  padding-top: 30px;
  margin-top: rem(-55px);
  .confirm-bundle-page{
    .list-bundle-item{
      margin-top: 80px;
      margin-right: 50px;
      height: 80vh;
      min-height: fit-content;
      overflow: scroll;
      
      .nft-card {
        max-width: 15.25rem;
        height: auto;
      }
      &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none; 
        }
    }
    .bundle-info{
      padding-top: 80px;
      font: $fw-bold rem(24px) $font-poppins-regular; 
      line-height: rem(24px);
      
      &__quantity{
        color: #E1E2E9;
        opacity: 0.5;
        font: $fw-normal rem(18px) $font-poppins-regular; 
        margin: rem(6px) 0 rem(40px);
      }
      .info-container{
        background: #333232;
        border-radius: 6px;
        color: #E1E2E9;
        font: $fw-normal rem(14px) $font-poppins-regular; 
        line-height: rem(21px);
        padding: rem(14px);
        margin-bottom: rem(24px);
        .network-container{
          margin-top: rem(14px);
          img{
            margin-right: rem(8px);
          }
        }

        .price-container{
          font-weight: 500;
          font-size: rem(18px);
          line-height: rem(27px);
          color: white;
          .token-logo{
            width: rem(24px);
            margin-right: rem(8px);
          }
        }
      }
      
      .btn-container{
        display: flex;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .bundle-detail .confirm-bundle-page .bundle-info {
    width: rem(330px);
  }
}

@media only screen and (max-width: 576px) {
  .bundle-detail {
    padding: rem(20px) 0;
    .confirm-bundle-page {
      overflow: scroll;
      height: 100vh;
      padding-bottom: 60px;
      .list-bundle-item {
        margin-top: 10px;
        margin-right: 0;
      }
      .bundle-info {
        border: none;
        padding-top: 50px;
        font-size: 16px;
        margin: 0 auto;
        &__quantity{
          font-size: 12px;
          margin-bottom: 10px;
        }
        .price-container{
          font-size: 20px;
          margin-bottom: 20px;
          .token-logo{
            width: 23px;

          }
        }
        
      }
    }
  }
}
  
